import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";
// import Lottie from "react-lottie-segments";
import lottie from "lottie-web/build/player/lottie_light";
import C_CarouselPanel from "../../2_components/carouselPanel/C_CarouselPanel";
import C_ProgressBar from "./C_ProgressBar";
import * as animationData from "./arrowSlide.json";
import fields from "./carouselOpts";

const S_Carousel = () => {
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);

  const [currSlide, setCurrSlide] = useState(1);
  const [currDuration, setCurrDuration] = useState(5);

  const [paused, setPaused] = useState(false);

  const [previewLeft, setPreviewLeft] = useState("");
  const [previewRight, setPreviewRight] = useState(
    "../images/fpo/section-carousel/fury-sample-peek.jpg",
  );

  const leftButtonContainer = useRef(null);
  const rightButtonContainer = useRef(null);
  const animBtnLeft = useRef(null);
  const animBtnRight = useRef(null);

  let totalSlides = 5;

  useEffect(() => {
    if (rightButtonContainer.current && leftButtonContainer.current) {
      animBtnRight.current = lottie.loadAnimation({
        container: rightButtonContainer.current,
        renderer: "svg",
        loop: false,
        autoplay: false,
        animationData,
      });

      animBtnLeft.current = lottie.loadAnimation({
        container: leftButtonContainer.current,
        renderer: "svg",
        loop: false,
        autoplay: false,
        animationData,
      });

      return () => {
        animBtnLeft.current?.destroy();
        animBtnRight.current?.destroy();
      };
    }
  }, []);

  const handleBtnEnter = (btn) => {
    if (btn === "left") {
      // animBtnLeft.current?.setDirection(1);
      animBtnLeft.current?.playSegments([0, 11], true);
    } else if (btn === "right") {
      // animBtnRight.current?.setDirection(1);
      animBtnRight.current?.playSegments([0, 11], true);
    }
  };

  const handleBtnLeave = (btn) => {
    if (btn === "left") {
      // animBtnLeft.current?.setDirection(-1);
      animBtnLeft.current?.playSegments([10, 0], true);
    } else if (btn === "right") {
      // animBtnRight.current?.setDirection(-1);
      animBtnRight.current?.playSegments([10, 0], true);
    }
  };

  const handleArrClick = (btn) => {
    if (btn === "left") {
      if (currSlide === 1) {
        setCurrSlide(totalSlides);
      } else if (currSlide === totalSlides) {
        setCurrSlide(currSlide - 1);
      } else {
        setCurrSlide(currSlide - 1);
      }
    }
    if (btn === "right") {
      if (currSlide === 1) {
        setCurrSlide(currSlide + 1);
        // setCurrSlide(totalSlides);
      } else if (currSlide === totalSlides) {
        setCurrSlide(1);
      } else {
        setCurrSlide(currSlide + 1);
      }
    }
  };

  useEffect(() => {
    let carouselVars = {
      ...appState,
      currentPath: "/ui/carousel",
      outputName: "Carousel",
      headerName: "Carousel Section",
      tags: null,
      description:
        "The carousel is an upgraded iteration of the Hero section with the addition of being able to add more than one image and having it cycle on its own to showcase multiple promos and announcements at the top of the page. *Drag and Drop image testing not available. *CTAs testable only on 1st slide",
      related: null,
      htmlSaved: true,
      currentTheme: null,
      scripts: [
        "../../js/rexusManager.component.js",
        "../../js/cuid.component.js",
        "jquery",
        "vimeo",
        "lottie",
        "../../js/vimeoPlayer.component.js",
        "../../js/vendor/dom-to-image.min.js",
        "carousel.section.js",
      ],
      specs: [
        [
          "Images (mobile, desktop)",
          ["*2 sizes required", "Small: 512 x 512", "Large: 2048 x 768"],
        ],
        ["Image Type", ["JPG"]],
        [
          "Video (mobile, desktop)",
          [
            "*2 sizes required",
            "Small: 512 x 512 / 1x1 ratio",
            "Large: 2048 x 640 / 24x9 ratio",
            "Must be 10 seconds long and hosted on Vimeo",
          ],
        ],
        ["Backup Video Image", ["*2 sizes required", "Small: 512 x 512", "Large: 2048 x 768"]],
        ["Backup Video Image Type", ["JPG"]],
        ["Peek Image", ["Peek Image Size: 52 x 720"]],
        ["Peek Image Type", ["JPG"]],
        ["Headline", ["*Optional. Character Limit: ~35"]],
        ["Description", ["Paragraph with character Limit: ~100"]],
        ["Clip Position", ["Position Clip 'Left' or 'Right'"]],
        ["CTA Icon", ["*Optional. Icon path or ID from icon map"]],
        ["CTA Text", ["*Optional. 28 Characters max, will not wrap"]],
        ["CTA Link", ["Valid URL to link CTA or slide"]],
        ["Duration", ["Minimum of 5 (seconds) increments of 1 second to X seconds"]],
      ],
      cis: {
        cmsDoc:
          "https://kingstontechnology.sharepoint.com/:w:/s/creative_fv/EWpKlGjRYhJDiyaQkNieuGEBake61VpuUebFqCGLLuzgJg?e=sWHLVs",
        comments: [
          ["Hero Image Size", ["Small: 512px x 512px", "Large: 2048px x 768px"]],
          ["Hero Video Size", ["Small: 512 x 512 / 1x1 ratio", "Large: 1280 x 720 / 16x9 ratio"]],
          ["Backup Video Image", ["Large: 2048px x 768px"]],
          ["Peek Image", ["Peek Image Size: 52px x 720px"]],
        ],
      },
    };
    setAppState(carouselVars);
    setContentOptions(fields.opts);
    setDimensions({
      ...dimensions,
      viewHeight: "",
      hardcodeHeight: false,
      width: window.innerWidth - 24,
    });
  }, []);

  //panel change on timer (timer in progress bar)
  useEffect(() => {
    if (currDuration === 0) {
      setCurrSlide(currSlide < 5 ? currSlide + 1 : 1);
      //currDuration set from within C_CarouselPanel component

      //set peek css var
      // document.documentElement.style.setProperty("--carousel-prev-slide", `url(${previewLeft})`);
      // document.documentElement.style.setProperty("--carousel-prev-slide", `url(${previewRight})`);
      // style =
      //   "--carousel-prev-slide:url(../images/fpo/section-carousel/nvme-sample-peek.jpg); --carousel-next-slide:url(../images/fpo/section-carousel/ssd-sample-peek.jpg);";
    }
  }, [currDuration]);

  //this context data must be loaded else errors are thrown, so show loading text
  if (!contentOptions) return "...Loading Hero";
  return (
    <section
      className={`s-carousel s-carousel--animate ${paused ? "s-carousel--paused" : ""}`}
      onMouseEnter={() => setPaused(true)}
      onMouseLeave={() => setPaused(false)}
      style={{
        "--carousel-prev-slide": "url(" + previewLeft + ")",
        "--carousel-next-slide": "url(" + previewRight + ")",
      }}
    >
      <div className="s-carousel__slides">
        <C_CarouselPanel
          type={"image"}
          time={5}
          pos={contentOptions.aligned && contentOptions.aligned.selected}
          hide={currSlide === 1 ? false : true}
          title={contentOptions.headline1 && contentOptions.headline1.field}
          //passing in whole desc obj here bc we want checkbox AND field vals
          copy={contentOptions.desc1 && contentOptions.desc1}
          lg={"../images/fpo/section-carousel/fury-sample-desktop.jpg"}
          sm={"../images/fpo/section-carousel/fury-sample-mobile.jpg"}
          dataPeek={"../images/fpo/section-carousel/fury-sample-peek.jpg"}
          leftPeek={() => setPreviewLeft("../images/fpo/section-carousel/nvme-sample-peek.jpg")}
          rightPeek={() => setPreviewRight("../images/fpo/section-carousel/nvme-sample-peek.jpg")}
          //passing in whole desc obj here bc we want checkbox AND field vals
          // file deepcode ignore CopyPasteError: not an error - this prevents errors when loading is slow
          cta={contentOptions.CTAP1 && contentOptions.CTAP1}
          //passing in whole desc obj here bc we want checkbox AND field vals
          icon={contentOptions.SVG1 && contentOptions.SVG1}
          currSlide={currSlide}
          setDuration={(val) => setCurrDuration(val)}
          slide={1}
        />
        <C_CarouselPanel
          type={"image"}
          time={5}
          pos={contentOptions.aligned && contentOptions.aligned.selected}
          hide={currSlide === 2 ? false : true}
          title={contentOptions.headline2 && contentOptions.headline2.field}
          copy={contentOptions.desc2 && contentOptions.desc2.field}
          lg={"../images/fpo/section-carousel/nvme-sample-desktop.jpg"}
          sm={"../images/fpo/section-carousel/nvme-sample-mobile.jpg"}
          dataPeek={"../images/fpo/section-carousel/nvme-sample-peek.jpg"}
          leftPeek={() => setPreviewLeft("../images/fpo/section-carousel/fury-sample-peek.jpg")}
          rightPeek={() => setPreviewRight("../images/fpo/section-carousel/kiwi-sample-peek.jpg")}
          cta="Buy It"
          icon="https://www.kingston.com/shared/icons-map.svg#play"
          currSlide={currSlide}
          setDuration={(val) => setCurrDuration(val)}
          slide={2}
        />
        <C_CarouselPanel
          type={"video"}
          time={7}
          pos={contentOptions.aligned && contentOptions.aligned.selected}
          hide={currSlide === 3 ? false : true}
          title={contentOptions.headline3 && contentOptions.headline3.field}
          copy={contentOptions.desc3 && contentOptions.desc3.field}
          lg={"https://vimeo.com/871931446?h=83f853fbff"}
          sm={"https://vimeo.com/335022330"}
          dataPeek={"../images/fpo/section-carousel/kiwi-sample-peek.jpg"}
          leftPeek={() => setPreviewLeft("../images/fpo/section-carousel/nvme-sample-peek.jpg")}
          rightPeek={() => setPreviewRight("../images/fpo/section-carousel/ssd-sample-peek.jpg")}
          cta="I'm wit it"
          icon="https://www.kingston.com/shared/icons-map.svg#play"
          currSlide={currSlide}
          setDuration={(val) => setCurrDuration(val)}
          slide={3}
        />
        <C_CarouselPanel
          type={"image"}
          time={8}
          pos={contentOptions.aligned && contentOptions.aligned.selected}
          hide={currSlide === 4 ? false : true}
          title={contentOptions.headline4 && contentOptions.headline4.field}
          copy={contentOptions.desc4 && contentOptions.desc4.field}
          lg={"../images/fpo/section-carousel/ssd-sample-desktop.jpg"}
          sm={"../images/fpo/section-carousel/ssd-sample-mobile.jpg"}
          dataPeek={"../images/fpo/section-carousel/ssd-sample-peek.jpg"}
          leftPeek={() => setPreviewLeft("../images/fpo/section-carousel/kiwi-sample-peek.jpg")}
          rightPeek={() => setPreviewRight("../images/fpo/section-carousel/nvme-sample-peek.jpg")}
          cta={null}
          currSlide={currSlide}
          setDuration={(val) => setCurrDuration(val)}
          slide={4}
        />
        <C_CarouselPanel
          type={"image"}
          time={5}
          pos={contentOptions.aligned && contentOptions.aligned.selected}
          hide={currSlide === 5 ? false : true}
          title={contentOptions.headline4 && contentOptions.headline4.field}
          copy={contentOptions.desc5 && contentOptions.desc5.field}
          lg={"../images/fpo/section-carousel/nvme-sample-desktop.jpg"}
          sm={"../images/fpo/section-carousel/nvme-sample-mobile.jpg"}
          dataPeek={"../images/fpo/section-carousel/nvme-sample-peek.jpg"}
          leftPeek={() => setPreviewLeft("../images/fpo/section-carousel/ssd-sample-peek.jpg")}
          rightPeek={() => setPreviewRight("../images/fpo/section-carousel/fury-sample-peek.jpg")}
          cta={null}
          currSlide={currSlide}
          setDuration={(val) => setCurrDuration(val)}
          slide={5}
        />
      </div>
      <button
        className="s-carousel__btn"
        data-direction="left"
        aria-label="Previous Slide"
        onClick={() => handleArrClick("left")}
        onMouseEnter={() => handleBtnEnter("left")}
        onMouseLeave={() => handleBtnLeave("left")}
        ref={leftButtonContainer}
      >
        {/* <Lottie
          name="leftButton"
          options={arrowOpts}
          height={56}
          width={56}
          playSegments={leftBtnOpts}
        /> */}
      </button>
      <button
        className="s-carousel__btn"
        data-direction="right"
        aria-label="Next Slide"
        onClick={() => handleArrClick("right")}
        onMouseEnter={() => handleBtnEnter("right")}
        onMouseLeave={() => handleBtnLeave("right")}
        ref={rightButtonContainer}
      >
        {/* <Lottie
          name="rightButton"
          options={arrowOpts}
          height={56}
          width={56}
          playSegments={rightBtnOpts}
        /> */}
      </button>
      <ol className="s-carousel__indicators u-list-unstyled u-m0 u-p0">
        <li>
          <button
            id="indicator0"
            data-parent="carousel0"
            data-slide="0"
            aria-controls="slide0_0"
            aria-pressed={currSlide === 1 ? true : false}
            aria-label="HEADING"
            onClick={() => setCurrSlide(1)}
          ></button>
        </li>
        <li>
          <button
            id="indicator1"
            data-parent="carousel0"
            data-slide="1"
            aria-controls="slide0_1"
            aria-pressed={currSlide === 2 ? true : false}
            aria-label="HEADING"
            onClick={() => setCurrSlide(2)}
          ></button>
        </li>
        <li>
          <button
            id="indicator2"
            data-parent="carousel0"
            data-slide="2"
            aria-controls="slide0_2"
            aria-pressed={currSlide === 3 ? true : false}
            aria-label="HEADING"
            onClick={() => setCurrSlide(3)}
          ></button>
        </li>
        <li>
          <button
            id="indicator3"
            data-parent="carousel0"
            data-slide="3"
            aria-controls="slide0_3"
            aria-pressed={currSlide === 4 ? true : false}
            aria-label="HEADING"
            onClick={() => setCurrSlide(4)}
          ></button>
        </li>
        <li>
          <button
            id="indicator4"
            data-parent="carousel0"
            data-slide="4"
            aria-controls="slide0_4"
            aria-pressed={currSlide === 5 ? true : false}
            aria-label="HEADING"
            onClick={() => setCurrSlide(5)}
          ></button>
        </li>
        <li>
          <button
            className="s-carousel__indicators__play"
            aria-label="Play Toggle"
            data-parent="carousel0"
            onClick={() => setPaused(!paused)}
          >
            <svg
              viewBox="0 0 24 24"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xlink="http://www.w3.org/1999/xlink"
            >
              <title>Play</title>
              <path d="M8.54259129,5.99642826 L16.5364288,11.1600032 C17.0003463,11.4596681 17.1334993,12.0786738 16.8338344,12.5425913 C16.7569038,12.6616891 16.6555267,12.7630663 16.5364288,12.8399968 L8.54259129,18.0035717 C8.07867384,18.3032366 7.45966805,18.1700836 7.16000316,17.7061662 C7.05555766,17.5444719 7,17.3560688 7,17.1635749 L7,6.8364251 C7,6.28414035 7.44771525,5.8364251 8,5.8364251 C8.19249387,5.8364251 8.38089705,5.89198277 8.54259129,5.99642826 Z"></path>
            </svg>
            <svg
              viewBox="0 0 24 24"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xlink="http://www.w3.org/1999/xlink"
            >
              <title>Pause</title>
              <path d="M8,6 C8.55228475,6 9,6.44771525 9,7 L9,17 C9,17.5522847 8.55228475,18 8,18 C7.44771525,18 7,17.5522847 7,17 L7,7 C7,6.44771525 7.44771525,6 8,6 Z M16,6 C16.5522847,6 17,6.44771525 17,7 L17,17 C17,17.5522847 16.5522847,18 16,18 C15.4477153,18 15,17.5522847 15,17 L15,7 C15,6.44771525 15.4477153,6 16,6 Z"></path>
            </svg>
          </button>
        </li>
      </ol>
      {/* <div
        className="s-carousel__progress"
        style={{ width: `${seconds / (currDuration * 1000)}%` }}
      ></div> */}
      <C_ProgressBar
        duration={currDuration}
        setDuration={(val) => setCurrDuration(val)}
        paused={paused}
        slide={currSlide}
      />
    </section>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved.
export default {
  path: "carousel",
  component: S_Carousel,
  navtxt: "Carousel",
  htmlName: "Carousel",
  categoryType: ["web"],
};
