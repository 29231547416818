/* eslint-disable jsx-a11y/label-has-associated-control */
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";

const S_Confirmation = () => {
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);

  //on document load equivalent
  useEffect(() => {
    let ckVars = {
      ...appState,
      currentPath: "/ui/confirmation",
      outputName: "Confirmation",
      headerName: "Confirmation",
      tags: null,
      description: "The Confirmation Section .....",
      related: [["Checkout: Default", "checkout"]],
      htmlSaved: true,
      scripts: [
        "../../js/rexusManager.component.js",
        "../../js/cuid.component.js",
        "jquery",
        "../../js/quantityCounter.component.js",
        // "../../js/forms.component.js",
        "../../js/dropDown.component.js",
        "../../js/formsLegacy.component.js",
        "checkoutEmea.section.js",
        "../../js/googlePlaces.component.js",
        "../../js/checkout.section.js",
        "../../js/accordion.component.js",
      ],
      specs: [
        [
          "Image (mobile, tablet, desktop)",
          ["Small, Medium, Large: Width and Height: Depends on the product item"],
        ],
        ["Image Type", ["No limitation to file format recommendation."]],
        ["Credit Card Image (mobile, tablet, desktop)", ["Width: 32px, Height: 20px"]],
        ["Credit Card Image Type", ["No limitation to file format recommendation."]],
        ["Title", ["A <h2> consists of string text"]],
        ["Copy Heading", ["A <p> consists of string text"]],
        ["Image Type", ["No limitation to file format recommendation."]],
        ["Question", ["A <span> consists of string text"]],
        ["Copy", ["A <p> consists of string text"]],
      ],
      cis: {
        cmsDoc:
          "https://kingstontechnology.sharepoint.com/:w:/s/creative_fv/EY9eExcb5pZIob4yq2PTZ20Bo2ReQwuybrgZOkHPCZiANg?e=1RVJ2f",
        comments: null,
      },
    };

    setAppState(ckVars);
    setContentOptions({
      savings: { label: "Savings", checkbox: true, field: null },
      currentLang: "Eng",
    });
    setDimensions({
      ...dimensions,
      viewHeight: "1323",
      hardcodeHeight: false,
      width: window.innerWidth - 24,
    });
  }, []);

  if (!contentOptions) return "...Loading Checkout: Confirmation";
  return (
    <section className="s-confirmation t-white">
      <div className="c-heading">
        <div className="c-heading__block">
          <div className="c-heading__block__header">
            <svg viewBox="0 0 16 20">
              <path d="M16 9.785C16 8.247 14.723 7 13.147 7H2.853C1.277 7 0 8.247 0 9.785v7.43C0 18.753 1.277 20 2.853 20h10.294a2.89 2.89 0 0 0 2.017-.816c.535-.522.836-1.23.836-1.969v-7.43zM9.333 15.08A1.04 1.04 0 0 1 8.28 16.11h-.56a1.04 1.04 0 0 1-1.053-1.028v-4.463A1.04 1.04 0 0 1 7.72 9.59h.56c.28 0 .547.108.745.3.197.193.308.455.308.728v4.463zM8.1.002a4.824 4.824 0 0 0-3.583 1.445 5.274 5.274 0 0 0-1.509 3.689c-.024.291.006.585.09.864h2.545c-.127 0-.19-.505-.19-.864 0-1.47 1.139-2.66 2.545-2.66 1.376 0 2.504 1.142 2.545 2.58 0 .359-.063.904-.19.904h2.545S13 5.441 13 5.082C12.959 2.28 10.783.024 8.1.002z"></path>
            </svg>
            <h1>Confirm Order</h1>
          </div>
        </div>
      </div>
      <div className="l-inner">
        <div className="l-grid l-grid--2col@lg">
          <div className="s-confirmation__summary">
            <div className="s-checkout__gridCheckout__summary">
              <div className="c-accordion c-accordion--iconAfter">
                <span className="c-accordion__tab c-accordion__tab--active" id="accTab-l5uokg">
                  <button aria-controls="accPanel-zhzu41" aria-expanded="true">
                    Order Summary
                    <svg
                      className="c-accordion__tab__icon"
                      aria-hidden="true"
                      width="14"
                      height="8"
                      viewBox="0 0 14 8"
                    >
                      <path d="M13.02.02a.062.062 0 0 0-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 0 0-.09 0L.02.977a.062.062 0 0 0 0 .09l6.937 6.915a.066.066 0 0 0 .09 0l.962-.96 5.973-5.954a.065.065 0 0 0 0-.09L13.02.02z"></path>
                    </svg>
                    <span className="u-txt-bold">$100.00</span>
                  </button>
                </span>
                <div
                  className="c-accordion__panel"
                  id="accPanel-zhzu41"
                  aria-labelledby="accTab-l5uokg"
                  //--delete this before committing. demo only
                  style={{ maxHeight: "500px" }}
                >
                  <div className={`c-accordion__panel__content c-cartCheckout`}>
                    <>
                      <ul className="u-list-unstyled c-cartCheckout__listing u-m0">
                        <li>
                          {/* <div className="c-cartItem__kit"></div> */}
                          <table className="c-cartCheckout__listing__item">
                            <tbody>
                              <tr>
                                <td>
                                  <img
                                    src="https://media.kingston.com/kingston/product/ktc-product-usb-dt70-64gb-1-tn.png"
                                    alt="product usb dt70 64gb"
                                  />
                                </td>
                                <td>
                                  Product 1
                                  <span className="c-cartCheckout__listing__item__pricePrimary u-txt-bold">
                                    <span>1</span>
                                    <span>x</span>
                                    <span>£43.00</span>
                                  </span>
                                </td>
                              </tr>
                              <tr></tr>
                            </tbody>
                          </table>
                        </li>

                        <li>
                          {/* <div className="c-cartItem__kit"></div> */}
                          <table className="c-cartCheckout__listing__item">
                            <tbody>
                              <tr>
                                <td>
                                  <img
                                    src="https://media.kingston.com/kingston/product/ktc-product-usb-dt70-64gb-1-tn.png"
                                    alt="product usb dt70 64gb"
                                  />
                                </td>
                                <td>
                                  Product 2
                                  <span className="c-cartCheckout__listing__item__pricePrimary u-txt-bold">
                                    <span>1</span>
                                    <span>x</span>
                                    <span>£43.00</span>
                                  </span>
                                </td>
                              </tr>
                              <tr></tr>
                            </tbody>
                          </table>
                        </li>
                      </ul>
                      <div className="c-cartCheckout__summary">
                        <table className="c-cartCheckout__summary__details">
                          <tbody>
                            <tr>
                              <td className="l-summary__link"></td>
                              <td className="l-summary__subTitle">Subtotal</td>
                              <td className="l-summary__subTotal">$1.50</td>
                            </tr>
                            <tr>
                              <td></td>
                              <td className="l-summary__shipTitle">Shipping</td>
                              <td className="l-summary__shpping">FREE</td>
                            </tr>
                            <tr>
                              <td></td>
                              <td className="l-summary__taxTitle">Tax</td>
                              <td className="l-summary__tax">$1.00</td>
                            </tr>
                            <tr>
                              <td className="l-summary__itemCount">1 Item</td>
                              <td className="l-summary__totalTitle">Total</td>
                              <td className="l-summary__total">$2.50</td>
                            </tr>
                            <tr>
                              <td></td>
                              <td className="l-summary__exVatTotal">Total (VAT excluded)</td>
                              <td className="l-summary__exVatTotal">$0.10</td>
                            </tr>
                            {contentOptions.savings && contentOptions.savings.checkbox == true ? (
                              <tr>
                                <td></td>
                                <td className="l-summary__saveTitle">Savings</td>
                                <td className="l-summary__savings">$0.10</td>
                              </tr>
                            ) : (
                              ""
                            )}
                          </tbody>
                        </table>
                      </div>
                    </>
                  </div>
                </div>
              </div>
              <span className="u-txt-bold">Are you a VAT registrered business outside the UK?</span>
              <p>
                You can register your VAT number so that we can verify your VAT number before
                exempting UK VAT from your order. This option will be available after you checkout..{" "}
              </p>
            </div>
            <div className="s-confirmation__summary__digicert">
              <div id="DigiCertClickID_d6a1ieXf" data-language="en">
                <a href="https://www.digicert.com/ssl-certificate.htm">DigiCert.com</a>
              </div>
            </div>
          </div>
          <div className="s-confirmation__information">
            <form>
              <fieldset className="s-confirmation__information__info">
                <h3>Contact Info</h3>
                <p>john_doe@company.com</p>
              </fieldset>
              <fieldset className="s-confirmation__information__deliveryAdd">
                <h3>
                  Delivery Address <a href="javascript:void(0)">Edit</a>
                </h3>
                <span className="s-confirmation__information__deliveryAdd__name">John Doe</span>
                <p className="s-confirmation__information__deliveryAdd__address">
                  <span>Company</span>
                  <span>1000 Something St</span>
                  <span>Build A, Creative</span>
                  <span>Acity, CA 91023</span>
                  <span>USA</span>
                </p>
                <p className="s-confirmation__information__deliveryAdd__phone">714-539-1138</p>
              </fieldset>
              <fieldset className="s-confirmation__information__shipOpts">
                <h3>Shipping Options</h3>
                <p>FedEX Ground 5-7 Days (M-F) - FREE</p>
              </fieldset>
              <fieldset>
                <div className="f-input">
                  <div className="f-input__checkbox">
                    <input type="checkbox" name="checkboxTest" id="checkboxTest" checked />
                    <label for="checkboxTest">
                      I agree to both the
                      <a href="javascript:void(0);">Terms & Conditions</a>
                      and the
                      <a href="javascript:void(0);">Privacy Policy</a>
                      of Shop Europe Ltd
                    </label>
                  </div>
                </div>
              </fieldset>
              <div className="s-confirmation__information__cta">
                <a href="javascript: void(0)" className="e-btn">
                  <svg>
                    <use xlinkHref="images/icons/icons-map.svg#factory-tested" />
                  </svg>
                  Confirm Order
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved.
export default {
  path: "confirmation",
  component: S_Confirmation,
  navtxt: "Confirmation",
  htmlName: "Confirmation",
  categoryType: ["internal"],
};
