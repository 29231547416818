export default {
  custom: {
    label: "Enable Custom",
    checkbox: false,
    field: null,
  },

  customheading: {
    label: "Main Heading [Type: Custom]",
    checkbox: null,
    field: {
      Eng: "AIC - HA201-TP Server",
      Span: "Título",
      ChS: "标题",
      Gr: "Überschrift",
      Ru: "Заголовок",
      Fr: "Titre",
      It: "Titolo",
      Pl: "Tytuł",
      Port: "Título",
      Viet: "Cú đội đầu",
      Turk: "Başlık",
      Ukrn: "Заголовок",
      Thai: "หัวเรื่อง",
      Kor: "표제",
      ChT: "標題 ",
      Jp: "見出し",
    },
  },

  heading: {
    label: "Heading",
    checkbox: null,
    field: {
      Eng: "System Information",
      Span: "Título",
      ChS: "标题",
      Gr: "Überschrift",
      Ru: "Заголовок",
      Fr: "Titre",
      It: "Titolo",
      Pl: "Tytuł",
      Port: "Título",
      Viet: "Cú đội đầu",
      Turk: "Başlık",
      Ukrn: "Заголовок",
      Thai: "หัวเรื่อง",
      Kor: "표제",
      ChT: "標題 ",
      Jp: "見出し",
    },
  },
  memorytitle: {
    label: "Memory Title",
    checkbox: true,
    field: {
      Eng: "Memory",
      Span: "Título",
      ChS: "标题",
      Gr: "Titel",
      Ru: "Заголовок",
      Fr: "Titre",
      It: "Titolo",
      Pl: "Tytuł",
      Port: "Título",
      Viet: "Tiêu đề",
      Turk: "Başlık",
      Ukrn: "Назва",
      Thai: "ชื่อ",
      Kor: "제목",
      ChT: "標題",
      Jp: "タイトル",
    },
  },
  memorysubtitle1: {
    label: "Memory Subtitle 1",
    checkbox: true,
    field: {
      Eng: "Standard",
      Span: "Subtitular",
      ChS: "副标题",
      Gr: "Untertitel",
      Ru: "Подзаголовок",
      Fr: "Sous-titre",
      It: "Sottotitolo",
      Pl: "Podtytuł",
      Port: "Legenda",
      Viet: "Phụ đề",
      Turk: "Alt yazı",
      Ukrn: "Підзаголовок",
      Thai: "คำบรรยาย",
      Kor: "부제",
      ChT: "副標題",
      Jp: "サブタイトル",
    },
  },
  memorycopy1: {
    label: "Memory Copy 1",
    checkbox: null,
    field: {
      Eng: "4 GB (Non-removable)",
      Span: "4 GB (no extraíble)",
      ChS: "4 GB（不可拆卸）",
      Gr: "4 GB (nicht entfernbar)",
      Ru: "4 ГБ (несъемный)",
      Fr: "4 Go (non amovible)",
      It: "4 GB (non rimovibili)",
      Pl: "4 GB (nieusuwalne)",
      Port: "4 GB (não removível)",
      Viet: "4 GB (Không thể tháo rời)",
      Turk: "4 GB (çıkarılamaz)",
      Ukrn: "4 ГБ (незнімний)",
      Thai: "4 GB (ไม่สามารถถอดออกได้)",
      Kor: "4GB(제거 불가)",
      ChT: "4 GB（不可拆卸)",
      Jp: "4 GB (取り外し不可)",
    },
  },
  memorycopy2: {
    label: "Memory Copy 2",
    checkbox: null,
    field: {
      Eng: "8 GB (Non-removable)",
      Span: "8 GB (no extraíble)",
      ChS: "8 GB（不可拆卸）",
      Gr: "8 GB (nicht entfernbar)",
      Ru: "8 ГБ (несъемный)",
      Fr: "8 Go (non amovible)",
      It: "8 GB (non rimovibili)",
      Pl: "8 GB (nieusuwalne)",
      Port: "8 GB (não removível)",
      Viet: "8 GB (Không thể tháo rời)",
      Turk: "8 GB (çıkarılamaz)",
      Ukrn: "8 ГБ (незнімний)",
      Thai: "8 GB (ไม่สามารถถอดออกได้)",
      Kor: "8GB(제거 불가)",
      ChT: "8 GB（不可拆卸)",
      Jp: "8 GB (取り外し不可)",
    },
  },
  memorysubtitle2: {
    label: "Add Memory Subtitle 2",
    checkbox: true,
    field: {
      Eng: "Maximum",
      Span: "Subtitular",
      ChS: "副标题",
      Gr: "Untertitel",
      Ru: "Подзаголовок",
      Fr: "Sous-titre",
      It: "Sottotitolo",
      Pl: "Podtytuł",
      Port: "Legenda",
      Viet: "Phụ đề",
      Turk: "Alt yazı",
      Ukrn: "Підзаголовок",
      Thai: "คำบรรยาย",
      Kor: "부제",
      ChT: "副標題",
      Jp: "サブタイトル",
    },
  },
  memorycta: {
    label: "Enable Memory CTA",
    checkbox: true,
    field: {
      Eng: "See memory upgrades",
      Span: "Actualizaciones de memoria compatibles",
      ChS: "兼容内存升级",
      Gr: "Kompatible Speicher-Upgrades",
      Ru: "Совместимые обновления памяти",
      Fr: "Mises à niveau de mémoire compatibles",
      It: "Aggiornamenti di memoria compatibili",
      Pl: "Kompatybilne aktualizacje pamięci",
      Port: "Atualizações de memória compatíveis",
      Viet: "Nâng cấp bộ nhớ tương thích",
      Turk: "Uyumlu Bellek Yükseltmeleri",
      Ukrn: "Оновлення сумісної пам'яті",
      Thai: "การอัพเกรดหน่วยความจำที่เข้ากันได้",
      Kor: "호환되는 메모리 업그레이드",
      ChT: "兼容內存升級",
      Jp: "互換性のあるメモリのアップグレード",
    },
  },
  memoryschema: {
    label: "Enable Bank Schema",
    checkbox: true,
    field: null,
  },
  memoryBankSchemaExample: {
    label: "Bank Schema Examples",
    checkbox: null,
    field: ["1", "2", "3", "4", "5"],
    selected: "1",
  },

  value: {
    label: "Value",
    checkbox: null,
    field: "2",
  },
  unit: {
    label: "Unit",
    checkbox: null,
    field: "GB",
  },

  storagetitle: {
    label: "Storage Title",
    checkbox: true,
    field: {
      Eng: "Storage",
      Span: "Título",
      ChS: "标题",
      Gr: "Titel",
      Ru: "Заголовок",
      Fr: "Titre",
      It: "Titolo",
      Pl: "Tytuł",
      Port: "Título",
      Viet: "Tiêu đề",
      Turk: "Başlık",
      Ukrn: "Назва",
      Thai: "ชื่อ",
      Kor: "제목",
      ChT: "標題",
      Jp: "タイトル",
    },
  },
  storagesubtitle1: {
    label: "Storage Subtitle 1",
    checkbox: null,
    field: {
      Eng: "Bus Architecture",
      Span: "Subtitular",
      ChS: "副标题",
      Gr: "Untertitel",
      Ru: "Подзаголовок",
      Fr: "Sous-titre",
      It: "Sottotitolo",
      Pl: "Podtytuł",
      Port: "Legenda",
      Viet: "Phụ đề",
      Turk: "Alt yazı",
      Ukrn: "Підзаголовок",
      Thai: "คำบรรยาย",
      Kor: "부제",
      ChT: "副標題",
      Jp: "サブタイトル",
    },
  },
  storagecopy1: {
    label: "Storage Copy 1",
    checkbox: null,
    field: {
      Eng: "Flash - SDXC",
      Span: "Hacía calor",
      ChS: "那个天气很热",
      Gr: "Tochter;",
      Ru: "Лорем ипсум",
      Fr: "Il faisait",
      It: "Faceva caldo",
      Pl: "Było gorąco",
      Port: "Fazia calor",
      Viet: "Thời tiết",
      Turk: "O hava",
      Ukrn: "У таку погоду",
      Thai: "อากาศช่วงนั้นร้อน",
      Kor: "국민경제의 발전을",
      ChT: "那個天氣很熱",
      Jp: "旅ロ京青利セム",
    },
  },
  storagecopy2: {
    label: "Storage Copy 2",
    checkbox: true,
    field: {
      Eng: "SSD - M.2 NVMe",
      Span: "Hacía calor",
      ChS: "那个天气很热",
      Gr: "Tochter;",
      Ru: "Лорем ипсум",
      Fr: "Il faisait",
      It: "Faceva caldo",
      Pl: "Było gorąco",
      Port: "Fazia calor",
      Viet: "Thời tiết",
      Turk: "O hava",
      Ukrn: "У таку погоду",
      Thai: "อากาศช่วงนั้นร้อน",
      Kor: "국민경제의 발전을",
      ChT: "那個天氣很熱",
      Jp: "旅ロ京青利セム",
    },
  },
  storagecopy3: {
    label: "Storage Copy 3",
    checkbox: null,
    field: {
      Eng: "SSD - M.2 SATA",
      Span: "Hacía calor",
      ChS: "那个天气很热",
      Gr: "Tochter;",
      Ru: "Лорем ипсум",
      Fr: "Il faisait",
      It: "Faceva caldo",
      Pl: "Było gorąco",
      Port: "Fazia calor",
      Viet: "Thời tiết",
      Turk: "O hava",
      Ukrn: "У таку погоду",
      Thai: "อากาศช่วงนั้นร้อน",
      Kor: "국민경제의 발전을",
      ChT: "那個天氣很熱",
      Jp: "旅ロ京青利セム",
    },
  },
  storagecopy4: {
    label: "Storage Copy 4",
    checkbox: true,
    field: {
      Eng: "USB 2.0/3.x Type-A",
      Span: "Hacía calor",
      ChS: "那个天气很热",
      Gr: "Tochter;",
      Ru: "Лорем ипсум",
      Fr: "Il faisait",
      It: "Faceva caldo",
      Pl: "Było gorąco",
      Port: "Fazia calor",
      Viet: "Thời tiết",
      Turk: "O hava",
      Ukrn: "У таку погоду",
      Thai: "อากาศช่วงนั้นร้อน",
      Kor: "국민경제의 발전을",
      ChT: "那個天氣很熱",
      Jp: "旅ロ京青利セム",
    },
  },
  storagecopy5: {
    label: "Storage Copy 5",
    checkbox: true,
    field: {
      Eng: "USB 2.0/3.x Type-C",
      Span: "Hacía calor",
      ChS: "那个天气很热",
      Gr: "Tochter;",
      Ru: "Лорем ипсум",
      Fr: "Il faisait",
      It: "Faceva caldo",
      Pl: "Było gorąco",
      Port: "Fazia calor",
      Viet: "Thời tiết",
      Turk: "O hava",
      Ukrn: "У таку погоду",
      Thai: "อากาศช่วงนั้นร้อน",
      Kor: "국민경제의 발전을",
      ChT: "那個天氣很熱",
      Jp: "旅ロ京青利セム",
    },
  },
  storagecta: {
    label: "Storage CTA",
    checkbox: true,
    field: {
      Eng: "See storage upgrades",
      Span: "Actualizaciones de almacenamiento compatibles",
      ChS: "兼容的存储升级",
      Gr: "Kompatible Speicher-Upgrades",
      Ru: "Совместимые обновления хранилища",
      Fr: "Mises à niveau de stockage compatibles",
      It: "Aggiornamenti di archiviazione compatibili",
      Pl: "Zgodne aktualizacje pamięci masowej",
      Port: "Atualizações de armazenamento compatíveisr",
      Viet: "Nâng cấp bộ nhớ tương thích",
      Turk: "Uyumlu Depolama Yükseltmeleri",
      Ukrn: "Оновлення сумісного сховища",
      Thai: "การอัปเกรดพื้นที่เก็บข้อมูลที่เข้ากันได้",
      Kor: "호환 가능한 스토리지 업그레이드",
      ChT: "兼容的存儲升級",
      Jp: "互換性のあるストレージのアップグレード",
    },
  },

  cputitle: {
    label: "CPU/Chipset Title",
    checkbox: true,
    field: {
      Eng: "CPU/Chipset",
      Span: "Título",
      ChS: "标题",
      Gr: "Titel",
      Ru: "Заголовок",
      Fr: "Titre",
      It: "Titolo",
      Pl: "Tytuł",
      Port: "Título",
      Viet: "Tiêu đề",
      Turk: "Başlık",
      Ukrn: "Назва",
      Thai: "ชื่อ",
      Kor: "제목",
      ChT: "標題",
      Jp: "タイトル",
    },
  },
  cpucopy1: {
    label: "CPU/Chipset Copy 1",
    checkbox: null,
    field: {
      Eng: "AMD A-Series APU 7th Gen AMD A320",
      Span: "Hacía calor en ese tiempo, para mí,",
      ChS: "那个天气很热，对我来说，我真的不知道……——嗯，",
      Gr: "Tochter; es hieß, sie sei mit Herrn ",
      Ru: "Лорем ипсум долор сит амет, пер цлита поссит",
      Fr: "Il faisait chaud dans ce temps-là, pour moi",
      It: "Faceva caldo con quel tempo, per me, non lo",
      Pl: "Było gorąco w taką pogodę, jak dla mnie",
      Port: "Fazia calor naquele tempo, pra mim",
      Viet: "Thời tiết đó nóng nực, đối với tôi",
      Turk: "O hava sıcaktı, benim için, pek",
      Ukrn: "У таку погоду було спекотно, для",
      Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ...",
      Kor: "국민경제의 발전을 위한 중요정책의 수립에",
      ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯",
      Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送",
    },
  },
  cpucopy2: {
    label: "CPU/Chipset Copy 2",
    checkbox: true,
    field: {
      Eng: "AMD Ryzen 5 1st/2nd/3rd Gen AMD",
      Span: "Hacía calor en ese tiempo, para mí,",
      ChS: "那个天气很热，对我来说，我真的不知道……——嗯，",
      Gr: "Tochter; es hieß, sie sei mit Herrn ",
      Ru: "Лорем ипсум долор сит амет, пер цлита поссит",
      Fr: "Il faisait chaud dans ce temps-là, pour moi",
      It: "Faceva caldo con quel tempo, per me, non lo",
      Pl: "Było gorąco w taką pogodę, jak dla mnie",
      Port: "Fazia calor naquele tempo, pra mim",
      Viet: "Thời tiết đó nóng nực, đối với tôi",
      Turk: "O hava sıcaktı, benim için, pek",
      Ukrn: "У таку погоду було спекотно, для",
      Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ...",
      Kor: "국민경제의 발전을 위한 중요정책의 수립에",
      ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯",
      Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送",
    },
  },
  cpucopy3: {
    label: "CPU/Chipset Copy 3",
    checkbox: true,
    field: {
      Eng: "AMD Ryzen 7 1st/2nd/3rd Gen AMD A320",
      Span: "Hacía calor en ese tiempo, para mí,",
      ChS: "那个天气很热，对我来说，我真的不知道……——嗯，",
      Gr: "Tochter; es hieß, sie sei mit Herrn ",
      Ru: "Лорем ипсум долор сит амет, пер цлита поссит",
      Fr: "Il faisait chaud dans ce temps-là, pour moi",
      It: "Faceva caldo con quel tempo, per me, non lo",
      Pl: "Było gorąco w taką pogodę, jak dla mnie",
      Port: "Fazia calor naquele tempo, pra mim",
      Viet: "Thời tiết đó nóng nực, đối với tôi",
      Turk: "O hava sıcaktı, benim için, pek",
      Ukrn: "У таку погоду було спекотно, для",
      Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ...",
      Kor: "국민경제의 발전을 위한 중요정책의 수립에",
      ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯",
      Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送",
    },
  },

  expansiontitle: {
    label: "Expansion Title",
    checkbox: true,
    field: {
      Eng: "Expansion",
      Span: "Título",
      ChS: "标题",
      Gr: "Titel",
      Ru: "Заголовок",
      Fr: "Titre",
      It: "Titolo",
      Pl: "Tytuł",
      Port: "Título",
      Viet: "Tiêu đề",
      Turk: "Başlık",
      Ukrn: "Назва",
      Thai: "ชื่อ",
      Kor: "제목",
      ChT: "標題",
      Jp: "タイトル",
    },
  },
  expansioncopy1: {
    label: "Expansion Copy 1",
    checkbox: null,
    field: {
      Eng: "1 Socket(s) internal SATA for SSD drive",
      Span: "Hacía calor en ese tiempo, para mí,",
      ChS: "那个天气很热，对我来说，我真的不知道……——嗯，",
      Gr: "Tochter; es hieß, sie sei mit Herrn ",
      Ru: "Лорем ипсум долор сит амет, пер цлита поссит",
      Fr: "Il faisait chaud dans ce temps-là, pour moi",
      It: "Faceva caldo con quel tempo, per me, non lo",
      Pl: "Było gorąco w taką pogodę, jak dla mnie",
      Port: "Fazia calor naquele tempo, pra mim",
      Viet: "Thời tiết đó nóng nực, đối với tôi",
      Turk: "O hava sıcaktı, benim için, pek",
      Ukrn: "У таку погоду було спекотно, для",
      Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ...",
      Kor: "국민경제의 발전을 위한 중요정책의 수립에",
      ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯",
      Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送",
    },
  },
  expansioncopy2: {
    label: "Expansion Copy 2",
    checkbox: null,
    field: {
      Eng: "1 Socket(s) external USB port USB drives",
      Span: "Hacía calor en ese tiempo, para mí,",
      ChS: "那个天气很热，对我来说，我真的不知道……——嗯，",
      Gr: "Tochter; es hieß, sie sei mit Herrn ",
      Ru: "Лорем ипсум долор сит амет, пер цлита поссит",
      Fr: "Il faisait chaud dans ce temps-là, pour moi",
      It: "Faceva caldo con quel tempo, per me, non lo",
      Pl: "Było gorąco w taką pogodę, jak dla mnie",
      Port: "Fazia calor naquele tempo, pra mim",
      Viet: "Thời tiết đó nóng nực, đối với tôi",
      Turk: "O hava sıcaktı, benim için, pek",
      Ukrn: "У таку погоду було спекотно, для",
      Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ...",
      Kor: "국민경제의 발전을 위한 중요정책의 수립에",
      ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯",
      Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送",
    },
  },
  expansionmemorycta: {
    label: "Expansion Memory CTA",
    checkbox: false,
    field: {
      Eng: "Compatible Memory Upgrades",
      Span: "Actualizaciones de memoria compatibles",
      ChS: "兼容内存升级",
      Gr: "Kompatible Speicher-Upgrades",
      Ru: "Совместимые обновления памяти",
      Fr: "Mises à niveau de mémoire compatibles",
      It: "Aggiornamenti di memoria compatibili",
      Pl: "Kompatybilne aktualizacje pamięci",
      Port: "Atualizações de memória compatíveis",
      Viet: "Nâng cấp bộ nhớ tương thích",
      Turk: "Uyumlu Bellek Yükseltmeleri",
      Ukrn: "Оновлення сумісної пам'яті",
      Thai: "การอัพเกรดหน่วยความจำที่เข้ากันได้",
      Kor: "호환되는 메모리 업그레이드",
      ChT: "兼容內存升級",
      Jp: "互換性のあるメモリのアップグレード",
    },
  },
  expansionstoragecta: {
    label: "Expansion Storage CTA",
    checkbox: false,
    field: {
      Eng: "Compatible Storage Upgrades",
      Span: "Actualizaciones de almacenamiento compatibles",
      ChS: "兼容的存储升级",
      Gr: "Kompatible Speicher-Upgrades",
      Ru: "Совместимые обновления хранилища",
      Fr: "Mises à niveau de stockage compatibles",
      It: "Aggiornamenti di archiviazione compatibili",
      Pl: "Zgodne aktualizacje pamięci masowej",
      Port: "Atualizações de armazenamento compatíveisr",
      Viet: "Nâng cấp bộ nhớ tương thích",
      Turk: "Uyumlu Depolama Yükseltmeleri",
      Ukrn: "Оновлення сумісного сховища",
      Thai: "การอัปเกรดพื้นที่เก็บข้อมูลที่เข้ากันได้",
      Kor: "호환 가능한 스토리지 업그레이드",
      ChT: "兼容的存儲升級",
      Jp: "互換性のあるストレージのアップグレード",
    },
  },

  upgradepathtitle: {
    label: "Upgrade path",
    checkbox: true,
    field: {
      Eng: "Upgrade Path",
      Span: "Ruta de actualización",
      ChS: "升级路径",
      Gr: "Upgrade-Pfad",
      Ru: "Путь обновления",
      Fr: "Chemin de mise à niveau",
      It: "Percorso di aggiornamento",
      Pl: "Ścieżka aktualizacji",
      Port: "Caminho de atualização",
      Viet: "Đường dẫn nâng cấp",
      Turk: "Yükseltme yolu",
      Ukrn: "Шлях оновлення",
      Thai: "อัพเกรดเส้นทาง",
      Kor: "업그레이드 경로",
      ChT: "升級路徑",
      Jp: "アップグレードパス",
    },
  },
  upgradepathsubtitle1: {
    label: "Upgrade path subtitle 1",
    checkbox: true,
    field: {
      Eng: "Reg ECC RDIMMs",
      Span: "Reg ECC RDIMMs",
      ChS: "Reg ECC RDIMMs",
      Gr: "Reg ECC RDIMMs",
      Ru: "Reg ECC RDIMMs",
      Fr: "Reg ECC RDIMMs",
      It: "Reg ECC RDIMMs",
      Pl: "Reg ECC RDIMMs",
      Port: "Reg ECC RDIMMs",
      Viet: "Reg ECC RDIMMs",
      Turk: "Reg ECC RDIMMs",
      Ukrn: "Reg ECC RDIMMs",
      Thai: "Reg ECC RDIMMs",
      Kor: "Reg ECC RDIMMs",
      ChT: "Reg ECC RDIMMs",
      Jp: "Reg ECC RDIMMs",
    },
  },
  upgradepathcopy1: {
    label: "Upgrade path copy 1",
    checkbox: true,
    field: {
      Eng: "Configurations up to 768GB can be reached using Kingston's 4, 8, 16, and 32GB modules IN GROUPS OF SIX.",
      Span: "Se pueden alcanzar configuraciones de hasta 768 GB utilizando los módulos de 4, 8, 16 y 32 GB de Kingston EN GRUPOS DE SEIS.",
      ChS: "使用金士顿的 4、8、16 和 32GB 模块（每组六个）可达到高达 768GB 的​​配置",
      Gr: "Konfigurationen bis zu 768 GB können mit Kingstons 4-, 8-, 16- und 32-GB-Modulen in Gruppen von sechs erreicht werden.",
      Ru: "Конфигурации объемом до 768 ГБ могут быть достигнуты с использованием модулей Kingston емкостью 4, 8, 16 и 32 ГБ ГРУППАМИ ПО ШЕСТЬ.",
      Fr: "Des configurations allant jusqu'à 768 Go peuvent être atteintes en utilisant les modules Kingston de 4, 8, 16 et 32 ​​Go PAR GROUPES DE SIX",
      It: "Configurazioni fino a 768 GB possono essere raggiunte utilizzando i moduli Kingston da 4, 8, 16 e 32 GB IN GRUPPI DI SEI",
      Pl: "Konfiguracje do 768 GB można osiągnąć, stosując moduły Kingston o pojemności 4, 8, 16 i 32 GB W GRUPACH PO SZEŚĆ",
      Port: "Configurações de até 768 GB podem ser alcançadas usando os módulos de 4, 8, 16 e 32 GB da Kingston EM GRUPOS DE SEIS",
      Viet: "Có thể đạt được cấu hình lên đến 768GB khi sử dụng các mô-đun 4, 8, 16 và 32GB của Kingston TRONG NHÓM SÁU",
      Turk: "Kingston'ın 4, 8, 16 ve 32 GB modülleri kullanılarak 768 GB'a kadar yapılandırmalara ALTILI GRUPLAR halinde ulaşılabilir",
      Ukrn: "Конфігурації до 768 ГБ можна отримати за допомогою модулів Kingston 4, 8, 16 і 32 ГБ У ГРУПАХ ПО ШЕСТИ",
      Thai: "รองรับการกำหนดค่าสูงสุดถึง 768GB โดยใช้โมดูล Kingston 4, 8, 16 และ 32GB ในกลุ่ม 6",
      Kor: "Kingston의 4, 8, 16, 32GB 모듈을 6개 그룹으로 사용하면 최대 768GB의 구성에 도달할 수 있습니다.",
      ChT: "使用金士頓 4、8、16 和 32GB 模組（六組）可實現高達 768GB 的​​配置",
      Jp: "キングストンの4、8、16、32GBモジュールを6個ずつ使用することで、最大768GBの構成を実現できます。",
    },
  },
  upgradepathsubtitle2: {
    label: "Upgrade path subtitle 2",
    checkbox: true,
    field: {
      Eng: "LRDIMMs",
      Span: "LRDIMMs",
      ChS: "LRDIMMs",
      Gr: "LRDIMMs",
      Ru: "LRDIMMs",
      Fr: "LRDIMMs",
      It: "LRDIMMs",
      Pl: "LRDIMMs",
      Port: "LRDIMMs",
      Viet: "LRDIMMs",
      Turk: "LRDIMMs",
      Ukrn: "LRDIMMs",
      Thai: "LRDIMMs",
      Kor: "LRDIMMs",
      ChT: "LRDIMMs",
      Jp: "LRDIMMs",
    },
  },
  upgradepathcopy2: {
    label: "Upgrade path copy 2",
    checkbox: true,
    field: {
      Eng: "Configurations up to 1.5TB can be reached using Kingston's 32 and 64GB modules IN GROUPS OF SIX.",
      Span: "Se pueden alcanzar configuraciones de hasta 1,5 TB utilizando los módulos de 32 y 64 GB de Kingston EN GRUPOS DE SEIS.",
      ChS: "通过使用金士顿的 32GB 和 64GB 模块（六个为一组），可以达到高达 1.5TB 的配置。",
      Gr: "Mit den 32- und 64-GB-Modulen von Kingston in Gruppen von sechs können Konfigurationen bis zu 1,5 TB erreicht werden.",
      Ru: "Конфигурации емкостью до 1,5 ТБ могут быть достигнуты при использовании модулей Kingston емкостью 32 и 64 ГБ ГРУППАМИ ПО ШЕСТЬ.",
      Fr: "Des configurations jusqu'à 1,5 To peuvent être atteintes en utilisant les modules 32 et 64 Go de Kingston PAR GROUPES DE SIX.",
      It: "È possibile raggiungere configurazioni fino a 1,5 TB utilizzando i moduli Kingston da 32 e 64 GB IN GRUPPI DA SEI..",
      Pl: "Konfiguracje do 1,5 TB można osiągnąć, stosując moduły Kingston o pojemności 32 i 64 GB W GRUPACH PO SZEŚĆ.",
      Port: "Configurações de até 1,5 TB podem ser alcançadas usando os módulos de 32 e 64 GB da Kingston EM GRUPOS DE SEIS.",
      Viet: "Có thể đạt được cấu hình lên đến 1,5TB khi sử dụng các mô-đun 32GB và 64GB của Kingston THEO NHÓM SÁU CÁI.",
      Turk: "Kingston'ın 32 ve 64 GB modüllerini ALTILI GRUPLAR HALİNDE kullanarak 1,5 TB'a kadar yapılandırmalara ulaşmak mümkün.",
      Ukrn: "Конфігурації до 1,5 ТБ можна отримати за допомогою модулів Kingston на 32 і 64 ГБ У ГРУПАХ ПО ШЕСТИ.",
      Thai: "สามารถกำหนดค่าได้สูงสุดถึง 1.5TB โดยใช้โมดูล Kingston ขนาด 32 และ 64GB ในกลุ่มละ 6 ตัว",
      Kor: "Kingston의 32GB 및 64GB 모듈을 6개 그룹으로 사용하면 최대 1.5TB까지의 구성에 도달할 수 있습니다.",
      ChT: "使用金士頓 32 和 64GB 模組（六組）可實現高達 1.5TB 的配置。",
      Jp: "Kingston の 32 GB および 64 GB モジュールを 6 個ずつ使用することで、最大 1.5 TB の構成を実現できます。",
    },
  },

  systempartnumberstitle: {
    label: "System Part Numbers",
    checkbox: true,
    field: {
      Eng: "System Part Numbers",
      Span: "Título",
      ChS: "标题",
      Gr: "Titel",
      Ru: "Заголовок",
      Fr: "Titre",
      It: "Titolo",
      Pl: "Tytuł",
      Port: "Título",
      Viet: "Tiêu đề",
      Turk: "Başlık",
      Ukrn: "Назва",
      Thai: "ชื่อ",
      Kor: "제목",
      ChT: "標題",
      Jp: "タイトル",
    },
  },
  systempartnumberscopy1: {
    label: "System Part Numbers Copy 1",
    checkbox: null,
    field: "20L7xxx; 20L8xxx",
  },

  importantnotestitle: {
    label: "Additional Card Title",
    checkbox: true,
    field: {
      Eng: "Important Configuration Notes",
      Span: "Título",
      ChS: "标题",
      Gr: "Titel",
      Ru: "Заголовок",
      Fr: "Titre",
      It: "Titolo",
      Pl: "Tytuł",
      Port: "Título",
      Viet: "Tiêu đề",
      Turk: "Başlık",
      Ukrn: "Назва",
      Thai: "ชื่อ",
      Kor: "제목",
      ChT: "標題",
      Jp: "タイトル",
    },
  },
  importantNotesExample: {
    label: "Additional Card Copy Examples",
    checkbox: null,
    field: ["1", "2", "3"],
    selected: "1",
  },
  importantnotescopy1: {
    label: "Additional Card Copy 1",
    checkbox: null,
    field: {
      Eng: "MODULES MUST BE ORDERED AND INSTALLED IN PAIRS for Dual Channel mode.",
      Span: "Hacía calor en ese tiempo, para mí, no sé muy bien... ",
      ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追，一泉合一。",
      Gr: "Tochter; es hieß, sie sei mit Herrn Schmidt durch den Hof kam Lotte",
      Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат мунере фабулас петентиум сит.",
      Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop... -- Eh bien",
      It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, molto lentamente",
      Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca... -- No, bardzo powoli",
      Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom, bem devagarinho",
      Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết...",
      Turk: "O hava sıcaktı, benim için, pek bilemiyorum.",
      Ukrn: "У таку погоду було спекотно, для мене, я не знаю..",
      Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ ",
      Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에",
      ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
      Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋ユム",
    },
  },
  importantnotescopy2: {
    label: "Additional Card Copy 2",
    checkbox: null,
    field: {
      Eng: "Faster memory will clock down to run at optimal speed depending on processor model installed.",
      Span: "Hacía calor en ese tiempo, para mí, no sé muy bien... ",
      ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追，一泉合一。",
      Gr: "Tochter; es hieß, sie sei mit Herrn Schmidt durch den Hof kam Lotte",
      Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат мунере фабулас петентиум сит.",
      Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop... -- Eh bien",
      It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, molto lentamente",
      Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca... -- No, bardzo powoli",
      Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom, bem devagarinho",
      Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết...",
      Turk: "O hava sıcaktı, benim için, pek bilemiyorum.",
      Ukrn: "У таку погоду було спекотно, для мене, я не знаю..",
      Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ ",
      Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에",
      ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
      Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋ユム",
    },
  },
  importantnotescopy3: {
    label: "Additional Card Copy 3",
    checkbox: null,
    field: {
      Eng: "If 4GB is installed on a 32-bit operating system, the recognized memory may be reduced to 3.5GB or less (depending on system configuration and memory allocation). Maximum configurations require a 64-bit operating system.",
      Span: "Hacía calor en ese tiempo, para mí, no sé muy bien... ",
      ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追，一泉合一。",
      Gr: "Tochter; es hieß, sie sei mit Herrn Schmidt durch den Hof kam Lotte",
      Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат мунере фабулас петентиум сит.",
      Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop... -- Eh bien",
      It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, molto lentamente",
      Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca... -- No, bardzo powoli",
      Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom, bem devagarinho",
      Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết...",
      Turk: "O hava sıcaktı, benim için, pek bilemiyorum.",
      Ukrn: "У таку погоду було спекотно, для мене, я не знаю..",
      Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ ",
      Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에",
      ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
      Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋ユム",
    },
  },

  furytitle: {
    label: "Additional Card 2 Title",
    checkbox: true,
    field: {
      Eng: "Fury Configuration Notes",
      Span: "Título",
      ChS: "标题",
      Gr: "Titel",
      Ru: "Заголовок",
      Fr: "Titre",
      It: "Titolo",
      Pl: "Tytuł",
      Port: "Título",
      Viet: "Tiêu đề",
      Turk: "Başlık",
      Ukrn: "Назва",
      Thai: "ชื่อ",
      Kor: "제목",
      ChT: "標題",
      Jp: "タイトル",
    },
  },

  furyExample: {
    label: "Additional Card 2 Copy Examples",
    checkbox: null,
    field: ["1", "2", "3", "4"],
    selected: "4",
  },

  furycopy1: {
    label: "Additional Card 2 Copy 1",
    checkbox: null,
    field: {
      Eng: "Lorem ipsum dolor sit amet, consectetur adipiscing elit quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum",
      Span: "Hacía calor en ese tiempo, para mí, no sé muy bien... -- Bueno, muy despacito, un día persiguiendo al otro, una primavera en una.",
      ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追，一泉合一。",
      Gr: "Tochter; es hieß, sie sei mit Herrn Schmidt durch den Hof kam Lotte fragte nach dem Hoftore anfuhren. Es war alles im Felde; nur ein Knabe von.",
      Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат мунере фабулас петентиум сит.",
      Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop... -- Eh bien, tout doucement, un jour chassant l'autre, un printemps sur un.",
      It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, molto lentamente, un giorno rincorrendo l'altro, una primavera dopo l'altra.",
      Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca... -- No, bardzo powoli, jeden dzień goniąc drugi, jedna wiosna w jedną.",
      Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom, bem devagarinho, um dia correndo atrás do outro, uma primavera na outra.",
      Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết... -- Chà, rất chậm, một ngày đuổi theo một ngày tiếp theo, một mùa xuân trong một.",
      Turk: "O hava sıcaktı, benim için, pek bilemiyorum... -- Şey, çok yavaş, bir gün diğerini kovalayarak, bir baharda.",
      Ukrn: "У таку погоду було спекотно, для мене, я не знаю... -- Ну, дуже повільно, один день за іншим, одна весна в одній.",
      Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่ตามสิ่งต่อไป หนึ่งฤดูใบไม้ผลิในหนึ่ง",
      Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여 국민경제자문회의를 둘 수 있다.",
      ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
      Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋ユムミク順待ふかんぼ人奨貯鏡すびそ。",
    },
  },
  furycopy2: {
    label: "Additional Card 2 Copy 2",
    checkbox: null,
    field: {
      Eng: "dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
      Span: "Hacía calor en ese tiempo, para mí, no sé muy bien... -- Bueno, muy despacito, un día persiguiendo al otro, una primavera en una.",
      ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追，一泉合一。",
      Gr: "Tochter; es hieß, sie sei mit Herrn Schmidt durch den Hof kam Lotte fragte nach dem Hoftore anfuhren. Es war alles im Felde; nur ein Knabe von.",
      Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат мунере фабулас петентиум сит.",
      Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop... -- Eh bien, tout doucement, un jour chassant l'autre, un printemps sur un.",
      It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, molto lentamente, un giorno rincorrendo l'altro, una primavera dopo l'altra.",
      Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca... -- No, bardzo powoli, jeden dzień goniąc drugi, jedna wiosna w jedną.",
      Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom, bem devagarinho, um dia correndo atrás do outro, uma primavera na outra.",
      Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết... -- Chà, rất chậm, một ngày đuổi theo một ngày tiếp theo, một mùa xuân trong một.",
      Turk: "O hava sıcaktı, benim için, pek bilemiyorum... -- Şey, çok yavaş, bir gün diğerini kovalayarak, bir baharda.",
      Ukrn: "У таку погоду було спекотно, для мене, я не знаю... -- Ну, дуже повільно, один день за іншим, одна весна в одній.",
      Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่ตามสิ่งต่อไป หนึ่งฤดูใบไม้ผลิในหนึ่ง",
      Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여 국민경제자문회의를 둘 수 있다.",
      ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
      Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋ユムミク順待ふかんぼ人奨貯鏡すびそ。",
    },
  },
  furycopy3: {
    label: "Additional Card 2 Copy 3",
    checkbox: null,
    field: {
      Eng: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed qui",
      Span: "Hacía calor en ese tiempo, para mí, no sé muy bien... -- Bueno, muy despacito, un día persiguiendo al otro, una primavera en una.",
      ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追，一泉合一。",
      Gr: "Tochter; es hieß, sie sei mit Herrn Schmidt durch den Hof kam Lotte fragte nach dem Hoftore anfuhren. Es war alles im Felde; nur ein Knabe von.",
      Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат мунере фабулас петентиум сит.",
      Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop... -- Eh bien, tout doucement, un jour chassant l'autre, un printemps sur un.",
      It: "Faceva caldo con quel tempo, per me, non lo so davvero... -- Beh, molto lentamente, un giorno rincorrendo l'altro, una primavera dopo l'altra.",
      Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca... -- No, bardzo powoli, jeden dzień goniąc drugi, jedna wiosna w jedną.",
      Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom, bem devagarinho, um dia correndo atrás do outro, uma primavera na outra.",
      Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết... -- Chà, rất chậm, một ngày đuổi theo một ngày tiếp theo, một mùa xuân trong một.",
      Turk: "O hava sıcaktı, benim için, pek bilemiyorum... -- Şey, çok yavaş, bir gün diğerini kovalayarak, bir baharda.",
      Ukrn: "У таку погоду було спекотно, для мене, я не знаю... -- Ну, дуже повільно, один день за іншим, одна весна в одній.",
      Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่ตามสิ่งต่อไป หนึ่งฤดูใบไม้ผลิในหนึ่ง",
      Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여 국민경제자문회의를 둘 수 있다.",
      ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
      Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケアナ住橋ユムミク順待ふかんぼ人奨貯鏡すびそ。",
    },
  },
  furycopy4: {
    label: "Additional Card 2 Copy 4",
    checkbox: null,
    field: {
      Eng: "consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.",
      Span: "Hacía calor en ese tiempo, para mí, no sé muy bien... -- Bueno, muy despacito",
      ChS: "那个天气很热，对我来说，我真的不知道……——嗯，很慢，一日追一追，一泉合一。",
      Gr: "Tochter; es hieß, sie sei mit Herrn Schmidt durch den Hof kam L",
      Ru: "Лорем ипсум долор сит амет, пер цлита поссит ех, ат мунере фабулас петентиум сит.",
      Fr: "Il faisait chaud dans ce temps-là, pour moi, je ne sais pas trop...",
      It: "Faceva caldo con quel tempo, per me, non lo so davvero... --",
      Pl: "Było gorąco w taką pogodę, jak dla mnie, nie wiem do końca... ",
      Port: "Fazia calor naquele tempo, pra mim, não sei bem... -- Bom,.",
      Viet: "Thời tiết đó nóng nực, đối với tôi, tôi thực sự không biết... -- ",
      Turk: "O hava sıcaktı, benim için, pek bilemiyorum... -- Şey, çok y",
      Ukrn: "У таку погоду було спекотно, для мене, я не знаю... -- Ну, ",
      Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ... -- ก็อย่างช้าๆ วันหนึ่งไล่ตามสิ่ง",
      Kor: "국민경제의 발전을 위한 중요정책의 수립에 관하여 대통령의 자문에 응하기 위하여 ",
      ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯，很慢，一日追一日，一春一春。",
      Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送でぼ調掲察たス日西重ケア",
    },
  },
  currentLang: "Eng",
};
