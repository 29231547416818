import React from "react";
import E_logoKingston from "../../../../1_elements/logoKingston/E_LogoKingston";
import bocLogo from "../../../../../../static/images/logos/kingston-logo-blkText.svg";

const Logo = () => {
  let logoZip = "https://www.kingstonloda.com/AssetLink/1uu17dkm461cgqu0m26b18u2565y5a14.zip";
  let imgClear1 = "https://www.kingstonloda.com/AssetLink/037h20n0cjp5d2k11eyw74y35ensp4h0.jpg";
  let imgClear2 = "https://www.kingstonloda.com/AssetLink/nsq2q0vpo541ua212mth157b03yk3322.jpg";
  let imgGuidance = "https://www.kingstonloda.com/AssetLink/knmcir2x8265x18e412y01v50414y4v5.svg";
  let imgIncorrectUsage =
    "https://www.kingstonloda.com/AssetLink/mnjk020t56564a5rrx5btfhs6ynr6402.svg";
  let BoCLogoZip = "https://www.kingstonloda.com/AssetLink/y617460j8fbp508iei515502n801gonq.zip";

  let bocLogoHorizBw =
    "https://www.kingstonloda.com/AssetLink/j8j37ytu2gicam074ra177s56si4igwp.svg";
  let bocLogoHorizBwRev =
    "https://www.kingstonloda.com/AssetLink/5158f6f40nes11mw438f1bmc807q4msb.svg";
  let bocLogoHorizRgbRev =
    "https://www.kingstonloda.com/AssetLink/jd461l13e7571v7u2wo0qcnclqe1a585.svg";
  let bocLogoHorizRgb =
    "https://www.kingstonloda.com/AssetLink/b32g8r0d7d00v7ed5f7gukp1a4o030x3.svg";
  let bocLogoRgb = "https://www.kingstonloda.com/AssetLink/au06740n1o3lfxp77mjimnssv747rqk7.svg";
  let bocLogoRgbRev = "https://www.kingstonloda.com/AssetLink/a3apili811x680cg031q04stfdy336yn.svg";

  return (
    <div className="s-content pg-brandGuidelines__logo">
      <div className="l-inner">
        <div className="l-row">
          <div className="s-content__headline">
            <h2>Kingston Logo</h2>
          </div>
          <div className="l-row">
            <div className="l-row__col">
              <div className="u-flex u-spaceBetween">
                <p>Kingston Logo + Tagline</p>
                <p>
                  {/* Download Logo raw SVG */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 14.05 15.64"
                    style={{ width: "1em", height: "1em" }}
                  >
                    <defs></defs>
                    <g>
                      <g>
                        <path d="M6.9 11.14a.73.73 0 01-.43-.14L1.75 6.27a.7.7 0 01-.17-.71.71.71 0 01.66-.48h2.27V.7a.7.7 0 01.7-.7h3.38a.7.7 0 01.7.7v4.38h2.27a.69.69 0 01.65.43.73.73 0 01-.17.77L7.33 11a.74.74 0 01-.43.14zm-4-5.08l4 4 4-4H8.32V1H5.48v5.05zm8.47-.46zm-9 0z"></path>
                        <path d="M14.05 15.64H0v-5.12h3.37v2h7.31v-2h3.37zm-7.12-1h6.15v-3.15h-1.43v2H2.41v-2H1v3.18z"></path>
                      </g>
                    </g>
                  </svg>
                  &nbsp;
                  <a className="u-txtSmall" href={BoCLogoZip} target="_blank" rel="noreferrer">
                    Download Logos
                  </a>
                </p>
              </div>
              <div className="l-grid l-grid--flush@sm l-grid--2col@md l-grid--2col@lg">
                <div className="pg-brandGuidelines__logo--large u-animated u-animated--slower a-fadeIn u-bgGreyL">
                  <img src={bocLogoRgb} style={{ gridColumn: "1 / span 2" }} />
                </div>

                <p style={{ gridColumn: "1 / span 2" }}>
                  The Kingston logo with its tagline consists of the "Rex" red head icon, the
                  logotype, the "Built on Commitment" tagline, and the registration symbol (®).
                  This logo is designed for use in Kingston communications and marketing materials.
                  It must always be displayed proportionally (as shown in the example) and should
                  not be modifed in any way. This version of the logo is not intended for use on
                  packaging.
                </p>

                <div className="pg-brandGuidelines__logo--tall u-animated u-animated--slower a-fadeIn u-bgGreyL">
                  <img src={bocLogoRgb} />
                </div>
                <div className="pg-brandGuidelines__logo--tall u-animated u-animated--slower a-fadeIn u-bgGrey">
                  <img src={bocLogoRgbRev} />
                </div>
                <div className="pg-brandGuidelines__logo--wide u-animated u-animated--slower a-fadeIn u-bgGrey">
                  <img src={bocLogoHorizRgbRev} />
                </div>
                <div className="pg-brandGuidelines__logo--wide u-animated u-animated--slower a-fadeIn u-bgGreyL">
                  <img src={bocLogoHorizRgb} />
                </div>
                <div className="pg-brandGuidelines__logo--wide u-animated u-animated--slower a-fadeIn u-bgGrey">
                  <img src={bocLogoHorizBwRev} />
                </div>
                <div className="pg-brandGuidelines__logo--wide u-animated u-animated--slower a-fadeIn u-bgGreyL">
                  <img src={bocLogoHorizBw} />
                </div>
              </div>
            </div>
            <div className="l-row__col pg-brandGuidelines__logo--2ndCol">
              <div className="u-flex u-spaceBetween">
                <p>Kingston Logo</p>
                <p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 14.05 15.64"
                    style={{ width: "1em", height: "1em" }}
                  >
                    <defs></defs>
                    <g>
                      <g>
                        <path d="M6.9 11.14a.73.73 0 01-.43-.14L1.75 6.27a.7.7 0 01-.17-.71.71.71 0 01.66-.48h2.27V.7a.7.7 0 01.7-.7h3.38a.7.7 0 01.7.7v4.38h2.27a.69.69 0 01.65.43.73.73 0 01-.17.77L7.33 11a.74.74 0 01-.43.14zm-4-5.08l4 4 4-4H8.32V1H5.48v5.05zm8.47-.46zm-9 0z"></path>
                        <path d="M14.05 15.64H0v-5.12h3.37v2h7.31v-2h3.37zm-7.12-1h6.15v-3.15h-1.43v2H2.41v-2H1v3.18z"></path>
                      </g>
                    </g>
                  </svg>
                  &nbsp;
                  <a className=" u-txtSmall" href={logoZip} target="_blank" rel="noreferrer">
                    Download Logos
                  </a>
                </p>
              </div>
              <div className="l-grid l-grid--flush@sm l-grid--2col@md l-grid--2col@lg">
                <div
                  className="pg-brandGuidelines__logo--large u-animated u-animated--slower a-fadeIn u-bgGreyL"
                  style={{ gridColumn: "1 / span 2" }}
                >
                  <E_logoKingston version={"black"} />
                </div>
                <p style={{ gridColumn: "1 / span 2" }}>
                  The offcial Kingston logo consists of the "Rex" red head icon, the logotype, and
                  the registration symbol (®). It is approved for use in all Kingston
                  communications and marketing materials. The logo must always maintain its
                  proportions (as shown in the example) and must not be modifed in any way.
                </p>

                <div className="pg-brandGuidelines__logo u-animated u-animated--slower a-fadeIn u-bgGreyL">
                  <E_logoKingston version={"black"} />
                </div>

                <div className="pg-brandGuidelines__logo u-animated u-animated--slower a-fadeIn u-bgGrey">
                  <E_logoKingston version={"white"} />
                </div>

                <div className="pg-brandGuidelines__logo u-animated u-animated--slower a-fadeIn u-bgGreyL">
                  <E_logoKingston version={"monoBlack"} />
                </div>

                <div className="pg-brandGuidelines__logo u-animated u-animated--slower a-fadeIn u-bgGrey">
                  <E_logoKingston version={"monoWhite"} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="l-row u-mb">
          {/* <div className="l-row__col l-1/2">
              
            </div> */}
          {/* <div className="l-row__col l-1/2">
              
            </div> */}
        </div>
        <div className="l-row">
          <div className="l-row__col l-1/1 l-1/3@lg">
            <strong>Minimum Logo Size</strong>
            <div className="u-flex u-alignCenter">
              <div
                style={{
                  minWidth: "165px",
                  width: "165px",
                  padding: "10px",
                  background: "black",
                  marginTop: ".5em",
                }}
              >
                <E_logoKingston version={"white"} />
              </div>
            </div>
            <div style={{ marginTop: ".5em" }}>
              <small>
                <span>Print:</span>
                <strong> 30mm</strong>
                <br />
                For size-restricted promo items and products only.
                <hr style={{ backgroundColor: "#ddd" }} />
                <span>Web / Screen:</span>
                <strong> 133px at 1080p</strong>
              </small>
            </div>
          </div>
        </div>
      </div>

      <div className="s-content" style={{ background: "#F7F9FA" }}>
        <div className="l-inner">
          <div className="l-row">
            <div className="s-content__headline l-row__col l-1/1">
              <h3>Clear Space</h3>
            </div>
          </div>
          <div className="l-row">
            <div className="l-row__col l-1/1">
              <p>
                The Kingston logo must have a clearance of the <strong>“n”</strong> height on all
                four sides that allow all applications free of type, graphics, and other elements
                that may visually clutter the logo.
              </p>
              <p>Examples:</p>
              <div className="u-flex">
                <div className="u-flex1" style={{ padding: ".5em", paddingLeft: 0 }}>
                  <img src={imgClear1} alt="Visual demonstrationg of 'N' height." />
                  <p>
                    <small>
                      Example showing proper <strong>&quot;n&quot;</strong> spacing around the
                      Kingston logo, free of text, image clutter and high-contrasting backgrounds.
                    </small>
                  </p>
                </div>
                <div className="u-flex1" style={{ padding: ".5em", paddingRight: 0 }}>
                  <img src={imgClear2} alt="Visual demonstrationg of 'N' height." />
                  <p>
                    <small>
                      This example shows where the <strong>&quot;n&quot;</strong> spacing is not
                      required. Logo containers are used to create separation from image clutter and
                      high-contrasting backgrounds.
                    </small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="s-content pg-brandGuidelines__guidance" style={{ background: "#F7F9FA" }}>
        <div className="l-inner">
          <div className="l-row">
            <div className="s-content__headline l-row__col l-1/1">
              <h3>Guidance</h3>
            </div>
          </div>
          <div className="l-row">
            <div className="l-row__col l-1/1 l-1/2@lg">
              <p>
                The Kingston logo is the fundamental branding element of Kingston Technology and
                it’s the foundation to our representation and recognition. It’s very important to
                keep the integrity of the Kingston logo.
              </p>
              <ul style={{ padding: 0, listStyle: "none" }}>
                <li>
                  <strong>A:</strong>{" "}
                  <strong style={{ color: "#C8102E", fontWeight: "800" }}>DON’T</strong> flip or
                  invert the red head “Rex” icon{" "}
                </li>
                <li>
                  <strong>B:</strong>{" "}
                  <strong style={{ color: "#C8102E", fontWeight: "800" }}>DON’T</strong> rotate the
                  logo
                </li>
                <li>
                  <strong>C:</strong>{" "}
                  <strong style={{ color: "#C8102E", fontWeight: "800" }}>DON’T</strong> change or
                  alter Rex’s proportions
                </li>
                <li>
                  <strong>D:</strong>{" "}
                  <strong style={{ color: "#C8102E", fontWeight: "800" }}>DON’T</strong> stretch,
                  squish or change proportion of logo
                </li>
                <li>
                  <strong>E:</strong>{" "}
                  <strong style={{ color: "#C8102E", fontWeight: "800" }}>DON’T</strong> use colors
                  other than those specified for the logo{" "}
                </li>
                <li>
                  <strong>F:</strong>{" "}
                  <strong style={{ color: "#C8102E", fontWeight: "800" }}>DON’T</strong> use
                  contrasting backgrounds behind the logo
                </li>
                <li>
                  <strong>G:</strong>{" "}
                  <strong style={{ color: "#C8102E", fontWeight: "800" }}>DON’T</strong> use any
                  other color than those specified for Rex{" "}
                </li>
                <li>
                  <strong>H:</strong>{" "}
                  <strong style={{ color: "#C8102E", fontWeight: "800" }}>DON’T</strong> use an
                  inverted white version of Rex
                </li>
                <li>
                  <strong>I:</strong>{" "}
                  <strong style={{ color: "#C8102E", fontWeight: "800" }}>DON’T</strong> introduce
                  any color effects on Rex
                </li>
              </ul>
            </div>
            <div className="l-row__col l-1/1 l-1/2@lg">
              <strong>Correct Usage:</strong>
              <img src={imgGuidance} width="800" height="550" alt="Logo Guidance visual examples" />
              <strong style={{ marginTop: ".25em", display: "block" }}>Incorrect Usage:</strong>
              <img
                src={imgIncorrectUsage}
                width="800"
                height="550"
                alt="Incorrect Usage Logo Guidelines"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="s-content">
        <div className="l-inner">
          <div className="l-row">
            <div className="s-content__headline l-row__col l-1/1">
              <h3>Specific Cases</h3>
            </div>
          </div>
          <div className="l-row">
            <div className="l-row__col l-1/1 l-2/5@lg">
              <p>
                In some instances, the Kingston logo has other variations based on specific cases.
              </p>
              <div className="u-flex" style={{ border: "1px solid black" }}>
                <div
                  className="u-flex1"
                  style={{ padding: ".75em .75em 0 .75em", background: "black" }}
                >
                  <img
                    src="https://www.kingstonloda.com/AssetLink/g50c2r4j30r271x5w05wuf38515nfkb6.svg"
                    width="300"
                    height="150"
                    alt="Visual demonstrationg of 'N' height."
                  />
                </div>
                <div className="u-flex1" style={{ padding: ".75em .75em 0 .75em" }}>
                  <img
                    src="https://www.kingstonloda.com/AssetLink/xk1437m8rng81qfsj82yeygj575l6fm8.svg"
                    width="300"
                    height="150"
                    alt="Kingston Logo specific case variation"
                  />
                </div>
              </div>
              <p>
                <strong>Raised Logotype</strong> — Used mainly for promotional merchandise and
                apparel due to print, color and/or size limitations. These output types include
                silkscreen, embroidery, stamping and laser etching.{" "}
                <strong>Minimum print: 0.75 inches.</strong>
              </p>
              <hr style={{ backgroundColor: "#333", margin: "2em 0" }} />
              <div className="u-flex" style={{ border: "1px solid black" }}>
                <div
                  className="u-flex1 u-txt-center"
                  style={{ paddingTop: ".25em", background: "black" }}
                >
                  <img
                    src="https://www.kingstonloda.com/AssetLink/jpsapry86on5vk06f4mriy3285qd1432.svg"
                    width="150"
                    height="150"
                    alt="Stacked Logo"
                    style={{ maxWidth: "120px" }}
                  />
                </div>
                <div className="u-flex1 u-txt-center" style={{ paddingTop: ".25em" }}>
                  <img
                    src="https://www.kingstonloda.com/AssetLink/n71356tr54t03r67706517061y277f01.svg"
                    width="150"
                    height="150"
                    alt="Stacked Logo"
                    style={{ maxWidth: "120px" }}
                  />
                </div>
              </div>
              <p>
                <strong>Stacked Red Head + Logotype</strong> — The stacked logo should only be used
                when the given space is either round or square. An example would be on responsive
                GDN banners as shown.
              </p>
            </div>
            <div className="l-row__col l-1/1 l-3/5@lg" style={{ paddingLeft: "2em" }}>
              <img
                src="https://www.kingstonloda.com/AssetLink/1082qieqrys70ea5vd5m4e050874x086.png"
                width="800"
                height="1000"
                alt="Example of Specific Cases"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Logo;
