/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AppContext } from "../../../AppContext.jsx";
import modules from "../../../workers/index.cjs";

const C_aside = () => {
  const location = useLocation();

  const { appState, setAppState } = useContext(AppContext);
  const [currPath, setCurrPath] = useState({ activePath: location.pathname });
  const [brands, setBrands] = useState([]);
  const [sections, setSections] = useState([]);
  const [brandClicked, setBrandClicked] = useState(false);
  const [sectionsClicked, setSectionsClicked] = useState(false);

  const visible = appState && appState.navVisible && appState.navVisible;

  //build arrays to save data for brand and web links to separate states.
  useEffect(() => {
    const bArr = [...brands];
    const sArr = [...sections];
    modules &&
      modules.forEach((module) => {
        // console.log("module: ", module);
        const path = module.path;
        if (path.includes("/brand/")) {
          bArr.push(module);
          setBrands(bArr);
        }
        if (path.includes("/ui/")) {
          sArr.push(module);
          setSections(sArr);
        }
      });
  }, [modules]);

  //set the active path on click
  const handleClick = (path) => {
    // console.log("path: ", path);
    if (currPath.activePath !== path) setCurrPath({ activePath: path });
    setAppState({ ...appState, navVisible: !visible, currentPath: path });
  };

  //test to find if each path in nav is current url. Will use if we want to style the current link in the nav.
  const testPath = (str) => {
    if (currPath.activePath !== "/") {
      // if (str !== "/" && currPath.activePath.indexOf(str) != -1) return true;
      if (str !== "/" && currPath.activePath === str) return true;
      return false;
    } else {
      //sep test for home page bc all have start with a slash
      if (currPath.activePath == str) return true;
      return false;
    }
  };

  if (!appState) return "...";
  return (
    <aside
      className={`v-aside ${visible ? "v-aside--active" : ""}`}
      id="v-asideMenu"
      style={{ display: visible ? "block" : "none", opacity: visible ? "1" : "0" }}
    >
      <div
        className="v-overlay"
        id="js-asideOverlay"
        onClick={() => setAppState({ ...appState, navVisible: !visible })}
        role="region"
      ></div>
      <ul className="v-aside__nav u-animated a-slideInLeft">
        <li
          className={`v-aside__nav__group u-animated u-animated--delayFast a-fadeIn v-aside__sections ${
            visible ? "v-aside__nav__group--active" : ""
          }`}
        >
          <div className="c-dropDown">
            <span
              className="c-dropDown__toggler"
              onClick={() => setBrandClicked(!brandClicked)}
              role="button"
              tabIndex={-3}
            >
              Brand Guidelines
            </span>
            <ul
              className={`u-list-unstyled u-m0 u-p0 c-dropDown__panel${
                brandClicked ? "--active" : ""
              }`}
            >
              {/* {brands.length > 0 &&
                brands.map(
                  (
                    module, // with a name, and routes
                    index,
                  ) => (
                    <li key={index} className={`${testPath(module.path) ? "current" : ""}`}>
                      <Link
                        to={module.path.replace(/:.+/, "")}
                        onClick={() => handleClick(module.path.replace(/:.+/, ""))}
                      >
                        <span>{module.navtxt}</span>
                      </Link>
                    </li>
                  ),
                )} */}
              <li>
                <Link
                  to="/brand/brand-guidelines/logos"
                  onClick={() => handleClick("/brand/brand-guidelines/logos")}
                >
                  <span>Kingston Logos</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/brand/brand-guidelines/colors"
                  onClick={() => handleClick("/brand/brand-guidelines/colors")}
                >
                  <span>Brand Colors</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/brand/brand-guidelines/typography"
                  onClick={() => handleClick("/brand/brand-guidelines/typography")}
                >
                  <span>Typography</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/brand/brand-guidelines/photography"
                  onClick={() => handleClick("/brand/brand-guidelines/photography")}
                >
                  <span>Photography</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/brand/brand-guidelines/video"
                  onClick={() => handleClick("/brand/brand-guidelines/video")}
                >
                  <span>Video</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/brand/brand-guidelines/tone"
                  onClick={() => handleClick("/brand/brand-guidelines/tone")}
                >
                  <span>Tone of Voice</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/brand/brand-guidelines/commitment"
                  onClick={() => handleClick("/brand/brand-guidelines/commitment")}
                >
                  <span>Built On Commitment</span>
                </Link>
              </li>
            </ul>
            <a
              href="/brand/fury/logo"
              className={`c-dropDown__toggler ${testPath("/brand/fury/logo") ? "current" : ""}`}
              onClick={() => handleClick("/brand/fury/logo")}
            >
              Kingston Fury
            </a>
            <a
              href="/brand/ironkey/logo"
              className={`c-dropDown__toggler ${testPath("/brand/ironkey/logo") ? "current" : ""}`}
              onClick={() => handleClick("/brand/ironkey/logo")}
            >
              Kingston IronKey
            </a>
            <span
              className="c-dropDown__toggler"
              onClick={() => setSectionsClicked(!sectionsClicked)}
              role="button"
              tabIndex={-2}
            >
              All Web Sections
            </span>
            <ul
              className={`${
                sectionsClicked
                  ? "u-list-unstyled u-p0 c-dropDown__panel--active"
                  : "u-list-unstyled u-m0 u-p0 c-dropDown__panel"
              }`}
            >
              {sections.length > 0 &&
                sections.map(
                  (
                    module, // with a name, and routes
                    index
                  ) => (
                    <li
                      key={index}
                      className={`${module.path === appState.currentPath ? "current" : ""}`}
                    >
                      <Link
                        to={module.path.replace(/:.+/, "")}
                        onClick={() => handleClick(module.path.replace(/:.+/, ""))}
                      >
                        <span>{module.navtxt}</span>
                      </Link>
                    </li>
                  )
                )}
            </ul>
            <a
              href="/icon-guide"
              className={`c-dropDown__toggler ${testPath("/icon-guide") ? "current" : ""}`}
              onClick={() => handleClick("/icon-guide")}
            >
              Icon Guide
            </a>
            <a
              href="/uiux"
              className={`c-dropDown__toggler ${testPath("/uiux") ? "current" : ""}`}
              onClick={() => handleClick("/uiux")}
            >
              UI Guide
            </a>
            <a
              href="/legal"
              className={`c-dropDown__toggler ${testPath("/legal") ? "current" : ""}`}
              onClick={() => handleClick("/legal")}
            >
              ISO/Legal
            </a>
          </div>
        </li>
      </ul>
    </aside>
  );
};

export default C_aside;
