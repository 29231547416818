import Fuse from "fuse.js";
import { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AppContext } from "../../../AppContext";
import modules from "../../../workers/index.cjs";
import "./c_search.scss";
import keywords from "./navkeywords.json";

const searchIcon = (
  <svg id="icon-search" viewBox="0 0 17.2 16.7">
    <path d="M9.8 0C5.7 0 2.4 3.3 2.4 7.4s3.3 7.4 7.4 7.4 7.4-3.3 7.4-7.4S13.9 0 9.8 0zm0 12.8c-3 0-5.3-2.4-5.3-5.3 0-3 2.4-5.3 5.3-5.3s5.3 2.4 5.3 5.3-2.4 5.3-5.3 5.3z" />
    <path d="M4.5 10.5L.4 14.6c-.5.5-.5 1.2 0 1.7s1.2.5 1.7 0l4.1-4.1" />
  </svg>
);

//SEARCH KEYWORD DATA
//use the modules data to create search data from sections
let keyData = () => {
  let arr = [];
  modules &&
    modules.forEach(function (module) {
      let obj = {};

      if (module.htmlName && module.path) {
        obj.topic = [module.navtxt];

        obj.url = module.path;
        //we are just using the section name and section type for the keywords bc we found using the description is overkill.
        obj.keywords = module.categoryType;
        if (Object.entries(obj).length !== 0) arr.push(obj);
      }
    });
  return arr;
};

//get data for web sections -
let sections = keyData();
//merge with data from IBG
keywords.push(...sections);

//END SEARCH KEYWORD DATA

const C_SiteSearch = () => {
  const [data, setData] = useState(keywords);
  const [active, setActive] = useState(false);
  const [matches, setMatches] = useState([]);
  const [matchActive, setMatchActive] = useState(false);

  const { appState, setAppState } = useContext(AppContext);

  const searchRef = useRef();
  const inputRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    document.addEventListener("keydown", handleEsc, true);
    document.addEventListener("click", clickOutside, true);
    //must clear even listeners when done
    return () => {
      document.removeEventListener("keydown", handleEsc, true);
      document.removeEventListener("click", clickOutside, true);
    };
  }, []);

  const toggleActive = () => {
    setActive(!active);
    inputRef.current.focus();
    //to clear search field
    inputRef.current.value = "";
    setMatchActive(false);
  };

  const handleEsc = (event) => {
    if (event.key === "Escape") {
      setActive(false);
      setData(keywords);
    }
  };

  const clickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setActive(false);
      setData(keywords);
    }
  };

  const doMatches = (searchtext) => {
    //fuse seems picky about options; keys might need to be first but it's not documented
    let options = {
      keys: ["topic", "keywords"],
      threshold: 0.3,
    };
    const fuse = new Fuse(data, options);

    const result = fuse.search(searchtext);
    const matches = [];
    // console.log("result: ", result);
    if (!result.length) {
      setMatches([]);
      setMatchActive(false);
    } else {
      result.forEach(({ item }) => {
        matches.push(item);
      });
      setMatches(matches);
    }
  };

  //this is where the search happens - on each letter entry in field
  const handleChange = (searchtext) => {
    // console.log("inside onhandlechange");
    // console.log("search text: ", searchtext);
    if (!searchtext) {
      setMatches(keywords);
      return;
    }
    doMatches(searchtext);
    setMatchActive(true);
  };

  const handleClose = (url) => {
    navigate(url);
    setActive(false);
    setData(keywords);
    setAppState({ ...appState, currentPath: url });
  };

  const handleKey = (e) => {
    // console.log("got enter key");

    if (e.key === "Enter") {
      let searchtext = decodeURIComponent(e.target.value);
      // console.log("field text: ", searchtext);
      // console.log("matches: ", matches);
      //   navigate(url);
      //   setActive(false);
      //   setData(keywords);
      //   setAppState({ ...appState, currentPath: e.target.value });
    }
  };

  return (
    <div className={`c-search c-search--minimal ${active && "c-search--active"}`} ref={searchRef}>
      <input
        className="c-search__input"
        type="text"
        onChange={(e) => handleChange(e.target.value)}
        placeholder="Search Guidelines"
        ref={inputRef}
        name="siteSearch"
        onKeyDown={(e) => handleKey(e)}
      />
      <button
        className="c-search__icon"
        aria-label="Search"
        onClick={() => toggleActive()}
        tabIndex={0}
      >
        {searchIcon}
      </button>
      {matchActive && (
        <div className={`c-search__panel u-animated u-animated--delayFast a-fadeIn`}>
          <ul>
            {matches.length > 0 ? (
              matches.map((item, i) => (
                <li key={i}>
                  <Link to={item.url} onClick={() => handleClose(item.url)}>
                    {item.topic}
                  </Link>
                </li>
              ))
            ) : (
              <li>
                <Link to={"#"}>
                  No search results for: <em>{inputRef.current.value}</em>
                </Link>
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default C_SiteSearch;
