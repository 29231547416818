import React from "react";
import Name1 from "./BumperEndCard.jsx";
let imgClear1 = "https://www.kingstonloda.com/AssetLink/oa68vd6vq8in86fuk8wkx6qt0jasm2v7.png";
let imgClear2 = "https://www.kingstonloda.com/AssetLink/36x55wxfnit18hofbd86k31401li4tge.png";

const Video = () => {
  return (
    <>
      <div className="s-content pg-brandGuidelines__video">
        <div className="l-inner">
          <div className="l-row l-row__col l-1/1">
            <div className="s-content__headline">
              <h2>Video Overview</h2>
            </div>
          </div>

          <div className="l-row">
            <div className="s-content__headline l-row__col l-1/1">
              <h3>Video Logo Bumper Guidelines</h3>
            </div>
          </div>

          <div className="l-row">
            <div className="l-row__col l-1/1">
              <p>
                The Kingston "Built on Commitment" video logo bumper was created to provide a
                consistent branding element for Kingston videos. They are to be used on all Kingston
                videos and any third-party-videos associated with Kingston.
              </p>
              <p>
                The video logo bumper is a quick 3-second video containing the Kingston logo with
                the new "BUILT ON COMMITMENT" tagline.
              </p>
              <div className="u-flex">
                <div className="u-flex1" style={{ padding: ".5em", paddingLeft: 0 }}>
                  <p style={{ fontWeight: "700" }}>4K Landscape</p>
                  <img src={imgClear1} alt="Visual demonstrationg of 'N' height." />
                </div>
                <div className="u-flex1" style={{ padding: ".5em", paddingRight: 0 }}>
                  <p style={{ fontWeight: "700" }}>1080p Vertical</p>
                  <img src={imgClear2} alt="Visual demonstrationg of 'N' height." />
                </div>
              </div>
            </div>
          </div>

          <div className="l-row">
            <div className="l-row__col l-1/1">
              <h3>Logo Bumper Usage</h3>
              <p>
                Kingston videos need to include the logo bumper at the end of the video. It is
                recommended that no other Kingston logo or branding is immediately proceeded or
                preceeded by it, as this may cause redundancy (though it's fine to have Kingston
                products and branding throughout the rest of the video, per Kingston branding
                guidelines).
              </p>
              <p>
                <strong style={{ color: "green", fontWeight: "800" }}>DO</strong> use the logo
                bumper at the end of any Kingston video..
              </p>
              <img
                src="https://www.kingstonloda.com/AssetLink/ju67p5ns5p5t531mus6421evo05873l4.png"
                alt=""
              />
              <p>
                <strong style={{ color: "#C8102E", fontWeight: "800" }}>DO NOT</strong> use any
                Kingston logo or branding proceeding or preceeding the logo bumper.{" "}
                <strong style={{ color: "#C8102E", fontWeight: "800" }}>DO NOT</strong> modify the
                logo bumper or use any other modified logo bumper.
              </p>
              <img
                src="https://www.kingstonloda.com/AssetLink/j0r5jauktr14h4p45ue4v4ub6hubt7yf.png"
                alt=""
              />
            </div>
          </div>

          <div className="l-row">
            <div className="s-content__headline l-row__col l-1/1">
              <h3>Video Formats</h3>
            </div>
          </div>

          <div className="l-row">
            <div className="l-row__col l-1/1">
              <p>
                The video logo bumpers are rendered out as H.264 MP4 video files. The following
                resolutions and aspect ratios are available:
              </p>
              <p>
                1. <span style={{ fontWeight: "700" }}>4K</span> - 3840x2160, 16:9 aspect ratio
                (YouTube, general broadcast)
              </p>
              <p>
                2. <span style={{ fontWeight: "700" }}>Vertical</span> - 1080x1920, 9:16 aspect
                ratio (Instagram Stories)
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Video;
