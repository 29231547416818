import React from "react";
import Name1 from "./BumperEndCard.jsx";
let overViewImgBg = "https://www.kingstonloda.com/AssetLink/678120208534p2603887334f0e3a1101.png";
let imgDontDo = "https://www.kingstonloda.com/AssetLink/r627rro15js1uaroh1250qd4n02bha58.png";
let imgLifestyle = "https://www.kingstonloda.com/AssetLink/4548st57rf264k1xbp6031hqd3s372fm.png";
let imgResearch = "https://www.kingstonloda.com/AssetLink/ad0rl85lo5koj5gb2o14471q71jcl52h.png";

const Photography = () => {
  return (
    <>
      <div className="s-content pg-brandGuidelines__photography" style={{ flex: "0 1 0%" }}>
        <div className="l-inner">
          <div className="l-row">
            <div className="s-content__headline l-row__col l-1/1">
              <h2>Photography Overview</h2>
              <p style={{ lineHeight: "1.75" }}>
                <strong>Capture stories about people's lives.</strong>
                <br />
                <strong>Considered compositions. </strong>
                <br />
                <strong>Spontaneous moments.</strong>
                <br />
                <strong>Energizing subject matter. </strong>
                <br />
                <strong>Confident, cool and candid.</strong>
                <br />
                <strong>Current and authentic.</strong>
              </p>
            </div>
          </div>
        </div>

        <div className="u-p0" style={{ flex: "1", position: "relative", display: "flex" }}>
          <div
            className="u-animated u-animated--slow a-fadeIn u-m0"
            style={{
              width: "100%",
              height: "auto",
              minHeight: "500px",
              background: `url('${overViewImgBg}') repeat-x`,
              backgroundSize: "cover",
            }}
          ></div>
        </div>

        {/* <div className="s-content"> */}
        <div className="l-inner">
          <div className="l-row l-row__col l-1/1">
            <h3>B2C vs B2B</h3>
            <div className="l-row">
              <p>
                The Kingston photography should feel real, not staged or posed. Moments captured
                spontaneously. The subjects should not be aware of the camera, and should be
                interacting with the products and the setting, going about their day-to-day life,
                with Kingston all around them.
              </p>
            </div>
          </div>

          <div className="l-row">
            <div className="l-row__col l-1/1 l-1/2@lg">
              <div className="l-row__col l-1/2 u-p0">
                <p>
                  <strong style={{ fontWeight: "800" }}>B2C</strong>
                </p>
                <p>
                  <strong style={{ fontWeight: "800" }}>It should be:</strong>
                </p>
                <ul className="u-p0">
                  <li>Warm</li>
                  <li>Human</li>
                  <li>Authentic</li>
                  <li>Vibrant</li>
                  <li>Street Photography</li>
                  <li>Day-to-day</li>
                  <li>life</li>
                  <li>Entertaining</li>
                  <li>Candid</li>
                  <li>Observant</li>
                  <li>Relatable</li>
                  <li>Personal</li>
                  <li>Inspiring</li>
                </ul>
                <p>
                  <strong style={{ fontWeight: "800" }}>It should NOT be:</strong>
                </p>
                <ul className="u-p0">
                  <li>Unnatural</li>
                  <li>Awkward</li>
                  <li>Posed</li>
                  <li>Forced</li>
                </ul>
              </div>
              <div className="l-row__col l-1/2 u-p0">
                <p>
                  <strong style={{ fontWeight: "800" }}>B2B</strong>
                </p>
                <p>
                  <strong style={{ fontWeight: "800" }}>It should be:</strong>
                </p>
                <ul className="u-p0">
                  <li>Cool or neutral tones</li>
                  <li>Human</li>
                  <li>Authentic</li>
                  <li>Business related</li>
                  <li>Technical</li>
                  <li>Real</li>
                  <li>Interesting</li>
                  <li>Candid</li>
                  <li>Observant</li>
                </ul>
                <p>
                  <strong style={{ fontWeight: "800" }}>It should NOT be:</strong>
                </p>
                <ul className="u-p0">
                  <li>Unnatural</li>
                  <li>Awkward</li>
                  <li>Posed</li>
                  <li>Forced</li>
                  <li>Bright and colorful</li>
                </ul>
              </div>
            </div>
            <div className="l-row__col l-1/1 l-1/2@lg">
              <div className="l-row__col l-1/2 u-p0">
                <p>
                  <strong>B2C</strong>
                </p>
              </div>
              <div className="l-row__col l-1/2 u-p0">
                <p>
                  <strong>B2B</strong>
                </p>
              </div>
              <img
                className="u-animated u-animated--fast a-fadeIn"
                src="https://www.kingstonloda.com/AssetLink/h1x6h4751ugfbnkmly7eg7no5v3bf45m.png"
                alt=""
              />
            </div>
          </div>
        </div>

        <div className="l-inner">
          <div className="l-row l-row__col l-1/1">
            <div className="pg-brandGuidelines__photography__guidance">
              <div className="l-row">
                <div className="s-content__headline">
                  <h3>Guidance</h3>
                </div>
              </div>
              <div className="l-row">
                <div>
                  <p>
                    At Kingston, we want to avoid dull, uninteresting, and staged photography. It's
                    better to use photography that is engaging, inspiring, and genuine.
                  </p>
                </div>
              </div>
              <div className="l-row">
                <div className="l-row__col l-1/1 l-1/2@lg">
                  <img
                    src={imgDontDo}
                    className="u-animated u-animated--fast a-fadeIn"
                    alt="Do use promotional items along with the Kingston logotype."
                  />
                </div>
                <div className="l-row__col l-1/1 l-1/2@lg">
                  <ul style={{ padding: 0, listStyle: "none" }}>
                    <li>
                      <strong>A:</strong>
                      <div>
                        <strong style={{ color: "#C8102E", fontWeight: "800" }}>DON'T</strong> use
                        images where people resemble stiff mannequin dolls
                      </div>
                    </li>
                    <li>
                      <strong>B:</strong>
                      <div>
                        <strong style={{ color: "#C8102E", fontWeight: "800" }}>DON'T</strong> use
                        images where the subject is unnaturally or incorrectly using a tech device
                      </div>
                    </li>
                    <li>
                      <strong>C:</strong>
                      <div>
                        <strong style={{ color: "#C8102E", fontWeight: "800" }}>DON'T</strong> use
                        images of people making hand signs to the camera
                      </div>
                    </li>
                    <li>
                      <strong>D:</strong>
                      <div>
                        <strong style={{ color: "#C8102E", fontWeight: "800" }}>DON'T</strong> use
                        images that are overly conceptual or colored outside of the corporate colors
                      </div>
                    </li>
                    <li>
                      <strong>E:</strong>
                      <div>
                        <strong style={{ color: "#C8102E", fontWeight: "800" }}>DON'T</strong> use
                        images of people being aware of and looking straight into the camera and
                        striking a pose
                      </div>
                    </li>
                    <li>
                      <strong>F:</strong>
                      <div>
                        <strong style={{ color: "#C8102E", fontWeight: "800" }}>DON'T</strong> find
                        imagery where people are posed with a fixated finger pointing to something
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="l-row ">
                <div className="s-content__headline">
                  <h3>Product Lifestyle</h3>
                </div>
              </div>
              <div className="l-row">
                <div className="l-row__col l-1/1">
                  <p>
                    Product lifestyle images should show the product in a realistic environment
                    helping to highlight the real life benefits to the end-user, this will ensure
                    that the images are always relevant and interesting to the target audience.
                  </p>
                  <p>
                    In some instances, the product can be shot on a simple textured background for a
                    simplistic approach to enhance the product’s design or features.
                  </p>
                </div>
                <div>
                  <img
                    src={imgLifestyle}
                    className="u-animated u-animated--fast a-fadeIn"
                    alt="Product Lifestyle Photography"
                  />
                </div>
              </div>

              <div className="s-content__headline">
                <div className="l-row">
                  <h3>Research &amp; Fact Check</h3>
                </div>
              </div>

              <div className="l-row">
                <div className="l-row__col l-1/1">
                  <p>
                    When creating assets for Kingston communications and marketing use, whether from
                    stock images or an actual photoshoot, please be mindful not to misrepresent
                    Kingston products.
                  </p>
                  <p>
                    For example, the message behind a product photograph might be to use Kingston
                    SSDs to upgrade the hard drive on a laptop for better and faster performance.
                    But you don’t want to use a MacBook Pro in the product photograph since MacBook
                    Pros aren’t upgradeable.
                  </p>
                  <p>
                    Light research and fact-checking may be necessary to correctly represent
                    Kingston and its products. It is important for all products used in product
                    photography to be appropriate and accurate for Kingston communications and
                    marketing purposes.
                  </p>
                </div>
                <div>
                  <img
                    src={imgResearch}
                    className="u-animated u-animated--fast a-fadeIn"
                    alt="Research and Fact Check Graphic"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Photography;
