import C_ProductSort from "../../2_components/productSort/C_ProductSort";

const PGTab5 = ({ sort }) => {
  return (
    <div className="s-productGallery3__filterLayout__view">
      <C_ProductSort sort={sort} />

      <div className={`s-productGallery3__filterLayout__view__content`}>
        <svg viewBox="0 0 24 24">
          <circle cx="17.238" cy="17.254" r="6" opacity=".2" />
          <path d="M17.238 10.504a6.75 6.75 0 1 1 0 13.5 6.75 6.75 0 0 1 0-13.5m0 1.5a5.25 5.25 0 1 0 0 10.5 5.25 5.25 0 0 0 0-10.5m0 7.5a1.125 1.125 0 1 1-.125.007zM12.866.004c.53 0 1.042.187 1.445.525l.146.133 2.873 2.871c.376.375.605.87.651 1.394l.009.198v3.129a.75.75 0 0 1-1.493.102l-.007-.102V5.125a.75.75 0 0 0-.143-.44l-.077-.09-2.873-2.872a.75.75 0 0 0-.413-.21l-.118-.009H2.238a.75.75 0 0 0-.743.648l-.007.102v16.5c0 .38.282.693.648.743l.102.007h6a.75.75 0 0 1 .102 1.493l-.102.007h-6a2.25 2.25 0 0 1-2.245-2.096l-.005-.154v-16.5A2.25 2.25 0 0 1 2.084.009l.154-.005zm6.226 14.263a2.625 2.625 0 0 1-1.854 4.483.75.75 0 0 1-.102-1.493l.102-.007a1.125 1.125 0 1 0-1.117-1.258l-.008.135a.75.75 0 1 1-1.5.004 2.625 2.625 0 0 1 4.48-1.864ZM5.988 12.004a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-1.5a1.5 1.5 0 0 1-1.5-1.5v-3a1.5 1.5 0 0 1 1.5-1.5Zm0 1.5h-1.5v3h1.5zm3-9a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-1.5a1.5 1.5 0 0 1-1.5-1.5v-3a1.5 1.5 0 0 1 1.5-1.5Zm-5.25 0a.75.75 0 0 1 .743.648l.007.102v4.5a.75.75 0 0 1-1.493.102l-.007-.102v-4.5a.75.75 0 0 1 .75-.75m9 0a.75.75 0 0 1 .743.648l.007.102v4.5a.75.75 0 0 1-1.493.102l-.007-.102v-4.5a.75.75 0 0 1 .75-.75m-3.75 1.5h-1.5v3h1.5z" />
        </svg>
        <p>
          No active products match your current filter selection. Try updating your filters to see
          more options.
        </p>
      </div>
    </div>
  );
};

export default PGTab5;
