/* eslint-disable import/no-unresolved */

import PageModules from "./PageModules.cjs";

// import all the module style pages

import * as miscPages from "../Styleguide/4_pages/*/index.jsx";
import * as ibgPages from "../Styleguide/4_pages/IBG/*/index.jsx";
import * as webUIPages from "../Styleguide/WebSections/3_sections/*/index.jsx";

// console.log("webUIPages: ", webUIPages);

/* above lines import this example type of data
{
    Pg_BrandElements: {
        // default: {
            path: "elements/:active_tab",
            component: Pg_brandElements,
            navtxt: "Brand Elements"
        }
    },
    Pg_BrandGuidelines: {
        default: {
            path: "elements/:active_tab",
            component: Pg_brandElements,
            navtxt: "Brand Elements"
        }
    }
}
*/
const modules = new PageModules();

// run brand guidelines and webcontent through "Pages" functions. Done separately bc they have different leading path parts.
modules.use("/brand/", ibgPages);
modules.use("/ui/", webUIPages);
modules.use("/", miscPages);

// console.log("modules in worker: ", modules);

export default modules.getData;

// export returns this kind of sample array
// [{
//   path: "/elements",
//   component: Pg_brandElements,
//   navtxt: "Brand Elements",
//   htmlName: "Brand Elements"
// },{
//   path: "/ambassadors",
//   component: S_Ambassadors,
//   navtxt: "Ambassadors",
//   icon: "ambassadors"
// }]
