import React from "react";

let imgChecklist = "https://www.kingstonloda.com/AssetLink/4w0onan24633xl1pm13l7365aibr3a20.png";

const Tone = () => {
  return (
    <>
      <div className="pg-brandGuidelines pg-brandGuidelines__tone s-content">
        <div className="l-inner">
          <div className="l-row">
            <div className="l-row__col l-1/1">
              <h2>Tone of Voice</h2>
            </div>
          </div>

          <div className="l-row">
            <div className="l-row__col l-1/1">
              <p className="u-txt-bold">
                It's important to be consistent in the way in which we communicate as it assists in
                defining our character and brand positioning.
              </p>
              <p>
                The overall Kingston tone of voice is the same across all communications. However,
                there are some differences between brand, B2B and B2C communications.
              </p>

              <div className="l-row">
                <h3 className="u-txt-bold">Brand</h3>
                <p>
                  The messaging should embody the brand values, with a focus on the 'Built on
                  Commitment' tagline. The communications should put Kingston in situations that are
                  relevant, sometimes surprising, engaging and interesting to the customer. The tone
                  of voice should be short, punchy, exciting and energizing.
                </p>

                <h3 className="u-txt-bold">B2B</h3>
                <p>
                  The B2B communications are more technical in their content and execution.
                  Kingston's style should convey honesty, confidence and authority to B2B customers.
                  It includes the benefits of our products, customer applications, problem solving
                  and cost savings (TCO).
                </p>

                <h3 className="u-txt-bold">B2C</h3>
                <p>
                  The B2C communications should be interesting, sometimes surprising, always
                  relevant and never boring. The tone of voice for B2C communications needs to be
                  plain-speaking and approachable.
                </p>

                <h3 className="u-txt-bold">Enthusiast/Gamer</h3>
                <p>
                  The Enthusiast communications should be more technical, interesting, sometimes
                  surprising, always relevant and never boring. The tone of voice for Enthusiast
                  communications needs to be informative, approachable, and focus on problem
                  solving.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pg-brandGuidelines s-content" style={{ background: "#F7F9FA" }}>
        <div className="l-inner">
          <div className="l-row">
            <div className="l-row__col l-1/1 l-4/10@lg">
              <img
                src={imgChecklist}
                alt="place"
                className="u-animated u-animated--slow a-fadeIn"
              />
            </div>
            <div className="l-row__col l-1/1 l-6/10@lg">
              <div className="pg-brandGuidelines__toneChecklist u-animated u-animated--slower a-fadeInLeft">
                <h3 style={{ margin: "0" }}>Checklist:</h3>
                <ul className="u-list-unstyled u-m0">
                  <li>
                    <label>
                      <input type="checkbox" name="accessible" />
                      <strong>How accessible is it?</strong>
                      <p>Is it clear and easy to understand?</p>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type="checkbox" name="accessible" />
                      <strong>Is it engaging?</strong>
                      <p>Is it interesting, short and punchy?</p>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type="checkbox" name="accessible" />
                      <strong>Is it relevant to your audience?</strong>
                      <p>
                        Some content doesn't translate well, so think of the people your content
                        needs to reach and talk to. How technical is the person you want to connect
                        with?
                      </p>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type="checkbox" name="accessible" />
                      <strong>Does it feel conversational?</strong>
                      <p>
                        Does it feel personal, engaging and talkative and not dull or long-winded?
                      </p>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type="checkbox" name="accessible" />
                      <strong>Does it come across as friendly?</strong>
                      <p>Is it approachable and inclusive in its tone?</p>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type="checkbox" name="accessible" />
                      <strong>Does it embody our values?</strong>
                      <p>
                        Is it trustworthy, authentic, supportive, accessible, flexible &amp; humble?
                      </p>
                    </label>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tone;
