import React from "react";
import C_ColorSwatch from "../../../../2_components/colorSwatch/C_ColorSwatch";

// let ironKeyLogo = "https://www.kingstonloda.com/AssetLink/32c85e0j3dkc17v4bsh2765n173ubv0a.svg";
// let ironKeyLogo2 = "https://www.kingstonloda.com/AssetLink/4i13285mr6xj0cx5o03rj064468p1m3l.svg";

// let ironKeyLogoSolo = "https://www.kingstonloda.com/AssetLink/vfgydut2dli6a301kfqky76l4ihf482i.png";
// let ironKeyLogoSolo2 =
//   "https://www.kingstonloda.com/AssetLink/62x403qs82k3288tjf62lre118s1jt01.png";

// const imgClear1 = "https://www.kingstonloda.com/AssetLink/6v4bfd3wxaw5xgbg24q7i7rq3o52w8k3.png";
// const imgClear2 = "https://www.kingstonloda.com/AssetLink/hj413w0bld4v0f2c8s1xqp055bp813a1.png";

// const incorrectUsageA =
//   "https://www.kingstonloda.com/AssetLink/8w0010i5ucrv4ici1u544p4ae2vkh770.svg";
// const incorrectUsageB =
//   "https://www.kingstonloda.com/AssetLink/81trv08y5om6re5qk08h522v001u1v1s.svg";
// const incorrectUsageC =
//   "https://www.kingstonloda.com/AssetLink/m217003tcr4nilwa5blw31204xyri4l5.svg";
// const incorrectUsageD =
//   "https://www.kingstonloda.com/AssetLink/7nyo0141k43g3i8jh2se1nb4c5278qp3.svg";
// const incorrectUsageE =
//   "https://www.kingstonloda.com/AssetLink/h6ses4n4x2ah030ng5yd05r7a07q351s.svg";
// const incorrectUsageF =
//   "https://www.kingstonloda.com/AssetLink/og74128qnohtt07k5k3n3hb15e2y6s1a.svg";

const Colors = () => {
  return (
    <>
      <div className="s-content">
        <div className="l-inner">
          <div className="l-row u-mt">
            <div className="l-row__col l-1/1">
              <h3>Primary Palette</h3>
              <div className="u-flex">
                <div className="u-flex1" style={{ padding: "1em .5em", paddingLeft: "0" }}>
                  <C_ColorSwatch hex={"#000000"} cmyk={"0/0/0/100"} rgb={"0/0/0"} />
                  <C_ColorSwatch hex={"#FFFFFF"} cmyk={"0/0/0/0"} rgb={"255/255/255"} />
                </div>
              </div>
            </div>
          </div>
          <div className="l-row">
            <div className="l-row__col l-1/1" style={{ paddingTop: "1em" }}>
              <h3>Secondary Accent Palette</h3>
              <p>
                The following may be used as accent colors for creating IronKey™ marketing
                materials. Please do not use any color but black or white for the IronKey logo.
              </p>
              <div className="u-flex">
                <div className="u-flex1" style={{ padding: "1em .5em", paddingLeft: "0" }}>
                  <C_ColorSwatch
                    hex={"#00A3E0"}
                    cmyk={"74/19/00/00"}
                    rgb={"0/163/224"}
                    pms={"299C"}
                  />
                  <C_ColorSwatch
                    hex={"#005EB8"}
                    cmyk={"91/67/00/00"}
                    rgb={"0/94/184"}
                    pms={"300C"}
                  />
                  <C_ColorSwatch
                    hex={"#6AD1E3"}
                    cmyk={"52/00/11/00"}
                    rgb={"106/209/227"}
                    pms={"310C"}
                  />
                  <C_ColorSwatch
                    hex={"#211E16"}
                    cmyk={"40/30/30/100"}
                    rgb={"33/30/22"}
                    pms={"Process Black C"}
                  />
                  <C_ColorSwatch
                    hex={"#454142"}
                    cmyk={"66/62/58/45"}
                    rgb={"69/65/66"}
                    pms={"4287C"}
                  />
                  <C_ColorSwatch
                    hex={"#53565A"}
                    cmyk={"66/57/52/29"}
                    rgb={"83/86/90"}
                    pms={"Cool Gray 11 C"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Colors;
