const H_dropZone = ({
  setPhone,
  setTablet,
  setDesktop,
  setImage,
  children,
  pictureTag,
  product,
}) => {
  const dragOver = (e) => {
    e.preventDefault();
  };

  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
  };

  const validateFile = (file) => {
    const validTypes = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/gif",
      "image/x-icon",
      "image/svg-xml",
      "image/webp",
    ];
    // const validTypes = "image/*";
    if (file && validTypes.indexOf(file.type) === -1) {
      return false;
    }
    return true;
  };

  const fileDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // console.log("inside file drop");
    if (pictureTag) {
      const files = e.target.files || e.dataTransfer.files;
      // console.log("inside has pic tag");
      for (let key in files) {
        let file = files[key];

        if (validateFile(file) === true) {
          let img = new Image();
          img.src = URL.createObjectURL(file);

          img.onload = () => {
            if (img.width <= 512) {
              setPhone({ phone: img.src });
            }
            if (img.width <= 1024 && img.width > 512) {
              setTablet({ tablet: img.src });
            }
            if (img.width > 1024) {
              setDesktop({ desktop: img.src });
            }
          };
        }
      }
    } else {
      const file = e.dataTransfer.files[0];
      // console.log("single file");
      if (validateFile(file) === true) {
        console.log("passed single validate file");
        setImage({ preview: URL.createObjectURL(file) });
      }
    }
  };

  return (
    <div className="container" style={{ height: "100%", width: "100%" }}>
      <div
        className="drop-container"
        style={
          product
            ? {
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }
            : { height: "100%", width: "100%" }
        }
        onDragOver={dragOver}
        onDragEnter={dragEnter}
        onDragLeave={dragLeave}
        onDrop={fileDrop}
      >
        {children}
      </div>
    </div>
  );
};

export default H_dropZone;
