export default {
  alignment: {
    label: "Text Align",
    checkbox: null,
    field: [
      "Top Left",
      "Top Center",
      "Top Right",
      "Middle Left",
      "Middle Right",
      "Bottom Left",
      "Bottom Center",
      "Bottom Right",
    ],
    selected: "Top Left",
  },
  txtColor: { label: "White Text Mode", checkbox: false, field: null },
  headingtype: {
    label: "Heading Type",
    checkbox: null,
    field: ["H1", "H2", "H3", "H4", "H5", "H6"],
    selected: "H4",
  },
  heading: {
    label: "Title",
    checkbox: true,
    field: {
      Eng: "Lorem Ipsum",
      Span: "Función Clave",
      ChS: "关键特点",
    },
    charLimit: 70,
  },
  desc: {
    label: "Copy",
    checkbox: true,
    field: {
      Eng: "Etiam sit amet nisl purus in mollis nunc sed id semper.",
      Span: "Aquí hay un texto que es una descripción de la función.",
      ChS: "这里有一些文字是特征描述。",
    },
    charLimit: 200,
  },
  primarybtn: [
    {
      label: "Primary Button",
      checkbox: true,
      field: { Eng: "Quis varius", Span: "Botón de texto", ChS: "按钮文字" },
      charLimit: 20,
    },
    {
      label: "Icon Primary button",
      checkbox: false,
      field: null,
    },
  ],
  secondarybtn: [
    {
      label: "Secondary Button",
      checkbox: false,
      field: { Eng: "Quis varius", Span: "Botón de texto", ChS: "按钮文字" },
      charLimit: 20,
    },
    {
      label: "Icon Secondary button",
      checkbox: true,
      field: null,
      charLimit: 20,
    },
  ],
  currentLang: "Eng",
};
