/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";
import headingOptions from "./headingOpts";

const S_Heading = () => {
  // These are needed for all sections
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);

  useEffect(() => {
    let headingVars = {
      ...appState,
      currentPath: "/ui/heading",
      outputName: "Heading",
      headerName: "Heading",
      tags: ["Landing Page Approved"],
      description:
        "The Heading Section displays a heading along with a brief subtitle and/or description.",
      htmlSaved: true,
      related: null,
      currentTheme: "t-white",
      scripts: ["../../js/rexusManager.component.js", "../../js/cuid.component.js"],
      specs: [
        [
          "Heading",
          [
            "Supports <h1> to <h6>, but the heading defaults to a <h2> tag, Character limit is recommended to be < 50",
          ],
        ],
        ["Subheading", ["Character limit is 120"]],
        [
          "Copy",
          [
            "The copy should be strictly text keeping the character count to under 300 for best results.",
          ],
        ],
        [
          "Heading Mode",
          [
            "Default: Supports <h1> to <h6>. Subheading and Description are available.",
            "Headline: Currently for Article pages. Supports <h1> only. Subheading and Description are not available.",
          ],
        ],
      ],
      cis: {
        cmsDoc:
          "https://kingstontechnology.sharepoint.com/:w:/s/creative_fv/EYelR3Qgw4pArb2XS4eWZ8oBHULQ4QuMH_ECtous_iAZ8w?e=X2csTe",
        comments: null,
      },
    };
    setAppState(headingVars);

    setContentOptions(headingOptions);
    setDimensions({ ...dimensions, hardcodeHeight: false, viewHeight: "168" });
  }, []);

  if (!contentOptions) return "...Loading Heading";
  return (
    <section className={`s-heading ` + appState.currentTheme}>
      <div
        className={`c-heading${contentOptions.headingmode && contentOptions.headingmode.selected == "Headline" ? " c-heading--headline" : ""}`}
      >
        <div className="c-heading__block">
          <div className="c-heading__block__header">
            {contentOptions.headingmode && contentOptions.headingmode.selected == "Headline" ? (
              <h1>
                {contentOptions.heading1 &&
                  contentOptions.heading1.field[contentOptions.currentLang]}
              </h1>
            ) : (
              <>
                {contentOptions.headingtype && contentOptions.headingtype.selected == "H1" ? (
                  <>
                    <h1>
                      {contentOptions.heading1 &&
                        contentOptions.heading1.field[contentOptions.currentLang]}
                    </h1>
                    {contentOptions.sub && contentOptions.sub.checkbox ? (
                      <h2 className="c-heading__block__header__subhead">
                        {contentOptions.sub.field[contentOptions.currentLang]}
                      </h2>
                    ) : (
                      ""
                    )}
                  </>
                ) : contentOptions.headingtype && contentOptions.headingtype.selected == "H2" ? (
                  <>
                    <h2>
                      {contentOptions.heading1 &&
                        contentOptions.heading1.field[contentOptions.currentLang]}
                    </h2>
                    {contentOptions.sub && contentOptions.sub.checkbox ? (
                      <h3 className="c-heading__block__header__subhead">
                        {contentOptions.sub.field[contentOptions.currentLang]}
                      </h3>
                    ) : (
                      ""
                    )}
                  </>
                ) : contentOptions.headingtype && contentOptions.headingtype.selected == "H3" ? (
                  <>
                    <h3>
                      {contentOptions.heading1 &&
                        contentOptions.heading1.field[contentOptions.currentLang]}
                    </h3>
                    {contentOptions.sub && contentOptions.sub.checkbox ? (
                      <h4 className="c-heading__block__header__subhead">
                        {contentOptions.sub.field[contentOptions.currentLang]}
                      </h4>
                    ) : (
                      ""
                    )}
                  </>
                ) : contentOptions.headingtype && contentOptions.headingtype.selected == "H4" ? (
                  <h4>
                    {contentOptions.heading1 &&
                      contentOptions.heading1.field[contentOptions.currentLang]}
                  </h4>
                ) : contentOptions.headingtype && contentOptions.headingtype.selected == "H5" ? (
                  <h5>
                    {contentOptions.heading1 &&
                      contentOptions.heading1.field[contentOptions.currentLang]}
                  </h5>
                ) : (
                  <h6>
                    {contentOptions.heading1 &&
                      contentOptions.heading1.field[contentOptions.currentLang]}
                  </h6>
                )}
              </>
            )}

            {contentOptions.copy &&
            contentOptions.copy.checkbox &&
            contentOptions.headingmode &&
            contentOptions.headingmode.selected == "Default" ? (
              <p>{contentOptions.copy.field[contentOptions.currentLang]}</p>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved.
export default {
  path: "heading",
  component: S_Heading,
  navtxt: "Heading",
  htmlName: "Heading",
  categoryType: ["landing page", "web"],
};
