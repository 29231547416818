import React from "react";

const Commitment = () => {
  let video = "https://youtu.be/VwyNeleF__4?si=goe8musDfc6YT9vl";
  return (
    <>
      <div className="s-content pg-brandGuidelines__commitment">
        <div className="l-inner">
          <div className="l-row">
            <div className="l-row__col l-1/1">
              <h2>Built On Commitment</h2>
            </div>
          </div>
          <div className="l-row">
            <div className="l-row__col l-1/1">
              <h3>Our Vision</h3>
              <p>
                Our brand vision, “Built on Commitment,” tagline encapsulates the essence of who we
                are and what we stand for: a company built on the strength of our products, the
                trust of our customers, and dedication to our employees.
              </p>
              <ul>
                <li>
                  <span style={{ fontWeight: "bold" }}>BUILT</span> signifies strength, continuous
                  improvement, and our long-standing history since 1987. It highlights our
                  industry-leading quality control, reliable products, and market leadership thanks
                  to our people, partners, and customers.
                </li>
                <li>
                  <span style={{ fontWeight: "bold" }}>COMMITMENT</span> underscores our lifetime
                  warranty, superb product support, long-term agreements, and our incredible
                  employee tenure. It emphasizes our dedication to fostering long-term
                  collaborations with our partners and our commitment to the growth and success of
                  our people. This vision places Kingston at the core of our customers' minds,
                  positioning us as a brand that supports businesses and consumers of all sizes. Our
                  goal is to make Kingston the leading independent memory and storage manufacturer,
                  known for quality products, innovative solutions, and extensive partnerships.
                </li>
              </ul>
              <div
                className="l-row__col l-1/1 l-1/2@lg"
                style={{ marginTop: "1em", paddingLeft: "0px", marginBottom: "2em" }}
              >
                <div>
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/VwyNeleF__4?si=Ly1W0CfmqZJlSzbI"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>

              <h3>Our Goal</h3>
              <p>
                A unified brand look and feel will ensure communications are instantly recognizable
                as Kingston and therefore strengthen the overall brand identity across different
                markets and target audiences, reinforcing Kingston's reputation as the leading
                independent memory and storage manufacturer. It will embody the vision and values
                across the different platforms and assets that Kingston uses.
              </p>
              <h3>Messaging Pillars</h3>
              <div className="l-row l-row__col--middle">
                <table className="pg-brandGuidelines__commitmentTable">
                  <tr>
                    <th colSpan="6">Kingston</th>
                  </tr>
                  <tr style={{ fontSize: "1.125em", fontWeight: "bold" }}>
                    <td colSpan="3">Quality</td>
                    <td colSpan="3">Reliability</td>
                  </tr>
                  <tr>
                    <td colSpan="2">Resources</td>
                    <td colSpan="2">Trust</td>
                    <td colSpan="2">Responsive</td>
                  </tr>
                  <tr>
                    <td colSpan="2">Support</td>
                    <td colSpan="2">Leadership</td>
                    <td colSpan="2">Flexibility</td>
                  </tr>
                  <tr>
                    <td colSpan="2">Strength</td>
                    <td colSpan="2">Knowledge</td>
                    <td colSpan="2">Reputation</td>
                  </tr>
                </table>
              </div>
              <h3>Our Purpose</h3>
              <p>
                The Kingston spirit reflects an individual commitment, based on teamwork and
                loyalty, to reach a standard of exceptional quality, reliability and service in
                providing system enhancements and solutions to our customers worldwide.
              </p>
              <h3>Brand Promise</h3>
              <p>
                Kingston is committed to providing our customers with the highest quality product
                solutions, service and support. At every touchpoint of the journey we are listening,
                learning and engaging with our customers.
              </p>
              <h3>Tagline Usage</h3>
              <p>
                "Built on Commitment" is the official tagline for Kingston Technology. This tagline
                should be used for most marketing purposes. It represents the identity of who we are
                as a brand and should be used where possible on assets.
              </p>
              <h3>Built on Commitment</h3>
              <ul>
                <li>Used with the logo or as standalone tagline </li>
                <li>Used on promotional merchandise and apparel</li>
                <li>Initial caps on first letter of each word or in all caps</li>
                <li>Can be used in visual assets, footers of documents, promo materials</li>
                <li>English only</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Commitment;
