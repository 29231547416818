import React from "react";

import C_LandingLarge from "./C_LandingLarge";
import C_LandingMedium from "./C_LandingMedium";

let brandImg = "/images/logos/kingston-logo-blkText.svg";
let ironkeyImg = "/images/logos/kingston-ironkey-White.png";
let furyImg = "/images/logos/Kingston_FURY_White_transparent.png";

const S_LandingGates = () => {
  return (
    <div className="s-landingGates u-animated u-animated--delayFast u-animated--slowest a-fadeIn">
      <div className="s-landingGates__row">
        <C_LandingLarge
          Img={brandImg}
          // title="Kingston Technology"
          link={"/brand/brand-guidelines/logo"}
          style={{ backgroundColor: "white" }}
          imgStyle={{ maxWidth: "27vw" }}
        />
      </div>
      <div className="s-landingGates__row">
        <C_LandingMedium
          Img={furyImg}
          // title="Fury"
          link={"/brand/fury"}
          style={{ backgroundColor: "#BA0C2F" }}
          imgStyle={{ maxWidth: "13vw" }}
        />
        <C_LandingMedium
          Img={ironkeyImg}
          // title="Ironkey"
          link={"/brand/ironkey/logo"}
          style={{ backgroundColor: " #005EB8" }}
          imgStyle={{ maxWidth: "16vw" }}
        />
      </div>
      <div className="s-landingGates__row">
        <C_LandingMedium
          // Img={iconImg}
          title="Icon Guide"
          link={"/icon-guide"}
          style={{ backgroundColor: "#353535" }}
        />
        <C_LandingMedium
          // Img={webImg}
          title="Web Guidelines"
          link={"/web-ui"}
          style={{ backgroundColor: "#353535" }}
        />

        <C_LandingMedium
          // Img={uxuiImg}
          title="UI Guide"
          link={"/uiux"}
          style={{ backgroundColor: "#353535" }}
        />
      </div>
      <div className="s-landingGates__submenu">
        <ul className="u-flex u-list-unstyled u-p0">
          <li>
            <h6>
              <a href="/legal">
                <svg aria-hidden="true">
                  <use xlinkHref="../images/icons-map.svg#right-pointer"></use>
                </svg>
                ISO / Legal
              </a>
            </h6>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default S_LandingGates;
