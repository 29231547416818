import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";
import DropZone from "../../../0_hooks/dropzone";
import heroOpts from "./heroOpts";
let phoneDefImg = "/images/fpo/general/512x512.png";
let tabDefImg = "/images/fpo/general/1024x512.png";
// import deskDefImg from "../../../../../static/images/fpo/2048x768.svg";
let deskDefImg = "/images/fpo/general/2048x768.png";

const S_Hero = () => {
  const { appState, setAppState } = useContext(AppContext);
  const [phone, setPhone] = useState(null);
  const [tablet, setTablet] = useState(null);
  const [desktop, setDesktop] = useState(null);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);

  useEffect(() => {
    let heroVars = {
      ...appState,
      currentPath: "/ui/hero",
      outputName: "Hero",
      headerName: "Hero Section",
      tags: ["Landing Page Approved"],
      description:
        "The hero section has two main areas for dynamic content, the background image/video (depending on where it's used) and foreground copy. The copy appears over the image in a 'clip' when in tablet or desktop view while the text will appear below the image in mobile view. There are several versions; please check related sections for possible options.",
      related: [
        ["Hero: Tagline", "hero-tagline"],
        ["Hero: Local Video", "hero-local-video"],
        ["Hero: SVP", "hero-svp"],
        ["Hero: Vimeo", "hero-vimeo"],
      ],
      htmlSaved: true,
      currentTheme: null,
      scripts: [],
      specs: [
        [
          "Background Images (mobile, tablet, desktop)",
          [
            "*All 3 image sizes required",
            "Small: 512 x 512",
            "Medium: 1024 x 475+",
            "Large: 2048 x 768",
          ],
        ],
        ["Background Image Type", ["JPG"]],
        ["Video (mobile, desktop)", ["*See Hero:Vimeo Section or Hero: Tagline"]],
        ["Headline", ["Character Limit: ~35"]],
        ["Link", ["Character Limit: 85"]],
        ["Tagline", ["Only available on home page"]],
      ],
      cis: {
        cmsDoc:
          "https://kingstontechnology.sharepoint.com/:w:/s/creative_fv/ERrpNEEjPsJDnsNmWhvM24EBtSgLQkXPkLXW98SL_rt4fw?e=me9xR8",
        comments: [
          [
            "Hero Image Sizes",
            ["Small: 512px x 512px", "Medium: 1024px x 475px+", "Large: 2048px x 768px"],
          ],
        ],
      },
    };
    setAppState(heroVars);
    setContentOptions(heroOpts);
    setDimensions({
      ...dimensions,
      hardcodeHeight: false,
      viewHeight: "768",
      width: window.innerWidth - 24,
    });
  }, []);

  //this context data must be loaded else errors are thrown, so show loading text
  if (!contentOptions) return "...Loading Hero";
  return (
    <DropZone setPhone={setPhone} setTablet={setTablet} setDesktop={setDesktop} pictureTag={true}>
      <section
        className={`s-hero${
          contentOptions.Aligned && contentOptions.Aligned.selected === "Right"
            ? " s-hero--clipRight"
            : contentOptions.Aligned && contentOptions.Aligned.selected === "Lower Left"
              ? " s-hero--clipLowerLeft"
              : contentOptions.Aligned && contentOptions.Aligned.selected === "Lower Right"
                ? " s-hero--clipLowerRight"
                : ""
        }${contentOptions.Alternate && contentOptions.Alternate.checkbox ? " s-hero--light" : ""}`}
      >
        <div className="s-hero__background">
          <picture className="e-picture" id="js-picture">
            <source
              srcSet={phone && phone.phone ? phone.phone : phoneDefImg}
              media="(max-width:32em)"
            />
            <source
              srcSet={tablet && tablet.tablet ? tablet.tablet : tabDefImg}
              media="(max-width:64em)"
            />
            <img
              src={desktop && desktop.desktop ? desktop.desktop : deskDefImg}
              alt="Alternate Text"
            />
          </picture>
        </div>

        <div className="s-hero__content">
          <div className="c-headerClip">
            {contentOptions.HeadlineMode && contentOptions.HeadlineMode.selected === "DIV" ? (
              <div className="u-h1 c-headerClip__title">
                {(contentOptions.Headline &&
                  contentOptions.Headline.field[contentOptions.currentLang]) ||
                  `Heading Text`}
              </div>
            ) : (
              <h1 className="c-headerClip__title">
                {(contentOptions.Headline &&
                  contentOptions.Headline.field[contentOptions.currentLang]) ||
                  `Heading Text`}
              </h1>
            )}
            <div className="c-headerClip__cta">
              {contentOptions.CTA && contentOptions.CTA.checkbox && (
                <a className="c-headerClip__cta__link" href="#heroLink">
                  {contentOptions.SVG.checkbox && contentOptions.SVG.field ? (
                    <svg>
                      <use xlinkHref={`/images/icons-map.svg${contentOptions.SVG.field}`} />
                    </svg>
                  ) : (
                    <svg>
                      <use xlinkHref="/images/icons-map.svg#play-basic" />
                    </svg>
                  )}
                  {contentOptions.CTA.field[contentOptions.currentLang] || `Hero Link`}
                </a>
              )}
            </div>
          </div>
        </div>
      </section>
    </DropZone>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved.
export default {
  path: "hero",
  component: S_Hero,
  navtxt: "Hero",
  htmlName: "Hero",
  categoryType: ["landing page", "web"],
};
