import { useContext, useEffect, useRef } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";
import heroSvpOpts from "./heroSvpOpts";
// import vals from "./svpPlayerVars";

const S_HeroSvp = () => {
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);

  const contRef = useRef();

  const playerVals = {
    clip_id: "eika2gd1ars4",
    transparent: "true",
    pause: "0",
    repeat: "1",
    bg_color: "#ffffff",
    fs_mode: "2",
    no_controls: "1",
    start_img: "0",
    start_volume: "0",
    close_button: "",
    brand_new_window: "1",
    auto_hide: "1",
    stretch_video: "true",
    player_align: "NONE",
    offset_x: "0",
    offset_y: "0",
    player_color_ratio: 0.6,
    skinAlpha: "0",
    colorBase: "#c8102e",
    colorIcon: "#ffffff",
    colorHighlight: "#A60B16",
    direct: "false",
    is_responsive: "true",
    viewers_limit: 0,
    cc_position: "bottom",
    cc_positionOffset: 70,
    cc_multiplier: 0.03,
    cc_textColor: "#ffffff",
    cc_textOutlineColor: "#ffffff",
    cc_bkgColor: "#000000",
    cc_bkgAlpha: 0.1,
    aspect_ratio: "16:9",
    play_button: "0",
    play_button_style: "pulsing",
    sleek_player: "1",
    auto_play: "1",
    auto_play_type: "mute",
    floating_player: "none"
  };

  useEffect(() => {
    let heroVars = {
      ...appState,
      currentPath: "/ui/hero-svp",
      outputName: "HeroSvp",
      headerName: "Hero Section w/Streaming video provider for China.",
      tags: null,
      description:
        "This hero section is a demo for a streaming provider altnernative to Vimeo that can be used in China. There are several versions; please check related sections for possible options.*Editable version doesn't have options",
      related: [
        ["Hero", "hero"],
        ["Hero: Tagline", "hero-tagline"],
        ["Hero: Local Video", "hero-local-video"],
        ["Hero: Vimeo", "hero-vimeo"]
      ],
      htmlSaved: true,
      currentTheme: null,
      scripts: [
        "../../js/rexusManager.component.js",
        "../../js/cuid.component.js",
        "../../js/svp.element.js"
      ],
      specs: [
        [
          "Background Video",
          ["Small: 512 x 512", "Large: 1280 x 720", "Video clip loop Length: 10 sec. or less"]
        ],
        [
          "Background Video Fallback Image",
          [
            "*2 sizes required",
            "Small: 512 x 512",
            "Large: 2048 x 768",
            "Recommended file size: 50kb to 100kb"
          ]
        ],
        ["Background Video Fallback Image Type", ["JPG"]],
        ["Headline", ["Character Limit: ~35"]],
        ["Link", ["Character Limit: 85"]],
        ["Tagline", ["Paragraph with character Limit: ~100"]]
      ],
      cis: {
        cmsDoc:
          "https://kingstontechnology.sharepoint.com/:w:/s/creative_fv/ERrpNEEjPsJDnsNmWhvM24EBtSgLQkXPkLXW98SL_rt4fw?e=me9xR8",
        comments: [
          ["Hero SVP Fallback Image", ["Small: 512 x 512", "Large: 2048 x 768"]],
          ["Hero Background Video", ["Small: 512 x 512", "Large: 1280 x 720"]]
        ]
      }
    };
    setAppState(heroVars);
    setContentOptions(heroSvpOpts);

    //hacky way to add videoplayer script
    const script = document.createElement("script");

    script.src = "https://play.webvideocore.net/js/dplayer.js";
    script.async = true;
    let comp = document.getElementsByClassName("e-svp");
    // let comp = contRef.current;

    setTimeout(function () {
      // console.log(vals);
      // console.log("comp: ", comp);
      document.body.appendChild(script);
      // eslint-disable-next-line no-undef
      const svpPlayer = new SVPDynamicPlayer(
        comp,
        "",
        "100%",
        "100%",
        {
          use_div: comp,
          skin: "3"
        },
        playerVals
      );
      svpPlayer.execute();
    }, 4000);

    //end hacky video player stuff

    setDimensions({
      ...dimensions,
      hardcodeHeight: false,
      viewHeight: "",
      width: window.innerWidth - 24
    });
  }, []);

  if (!contentOptions) return "...Loading Hero";
  return (
    <section
      className={`s-hero${
        contentOptions.Aligned && contentOptions.Aligned.selected === "Right"
          ? " s-hero--clipRight"
          : contentOptions.Aligned && contentOptions.Aligned.selected === "Lower Left"
            ? " s-hero--clipLowerLeft"
            : contentOptions.Aligned && contentOptions.Aligned.selected === "Lower Right"
              ? " s-hero--clipLowerRight"
              : ""
      }${contentOptions.Alternate && contentOptions.Alternate.checkbox ? " s-hero--light" : ""}`}
    >
      <div
        className="s-hero__background"
        style={{
          "--fallbackSm": `url("https://media.kingston.com/kingston/landing/ironkey/ironkey_forge_Homepage-sm.jpg")`,
          "--fallbackLg": `url("/images/fpo/section-carousel/kiwi-sample-backup.jpg")`
        }}
      >
        <div
          className="e-svp"
          data-small-video="euis6sdzh008"
          data-large-video="dk6w3nipntsg"
          // data-initialized="true"
          // style={{
          //   width: "100%",
          //   height: "auto",
          //   paddingTop: "56.25%",
          //   boxShadow: "rgba(48, 48, 48, 0.4) 0px 20px 22px -8px",
          //   borderRadius: "4px",
          //   overflow: "hidden",
          // }}
          ref={contRef}
        ></div>
      </div>
      <div className="s-hero__content">
        <div className="c-headerClip">
          {contentOptions.HeadlineMode && contentOptions.HeadlineMode.selected === "DIV" ? (
            <div className="u-h1 c-headerClip__title">
              {(contentOptions.Headline &&
                contentOptions.Headline.field[contentOptions.currentLang]) ||
                `Heading Text`}
            </div>
          ) : (
            <h1 className="c-headerClip__title">
              {(contentOptions.Headline &&
                contentOptions.Headline.field[contentOptions.currentLang]) ||
                `Heading Text`}
            </h1>
          )}
          <div className="c-headerClip__cta">
            {contentOptions.CTA && contentOptions.CTA.checkbox && (
              <a className="c-headerClip__cta__link" href="#heroLink">
                {contentOptions.SVG.checkbox && contentOptions.SVG.field ? (
                  <svg>
                    <use xlinkHref={`/images/icons-map.svg${contentOptions.SVG.field}`} />
                  </svg>
                ) : (
                  <svg>
                    <use xlinkHref="/images/icons-map.svg#play-basic" />
                  </svg>
                )}
                {contentOptions.CTA.field[contentOptions.currentLang] || `Hero Link`}
              </a>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved.
export default {
  path: "hero-svp",
  component: S_HeroSvp,
  navtxt: "Hero China SVP",
  htmlName: "HeroSvp",
  categoryType: ["web"]
};
