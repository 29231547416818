/* eslint-disable jsx-a11y/label-has-associated-control */
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";

const S_CheckoutThankYou = () => {
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);

  //on document load equivalent
  useEffect(() => {
    let ckVars = {
      ...appState,
      currentPath: "/ui/checkout-thankyou",
      outputName: "CheckoutThankYou",
      headerName: "Checkout: Thank You",
      tags: null,
      description: "The Checkout Thank You Section...",
      related: [["Checkout: Default", "checkout"]],
      htmlSaved: true,
      scripts: [
        "../../js/rexusManager.component.js",
        "../../js/cuid.component.js",
        "jquery",
        "../../js/quantityCounter.component.js",
        // "../../js/forms.component.js",
        "../../js/dropDown.component.js",
        "../../js/formsLegacy.component.js",
        "checkoutEmea.section.js",
        "../../js/googlePlaces.component.js",
        "../../js/checkout.section.js",
        "../../js/accordion.component.js",
      ],
      specs: [
        [
          "Image (mobile, tablet, desktop)",
          ["Small, Medium, Large: Width and Height: Depends on the product item"],
        ],
        ["Image Type", ["No limitation to file format recommendation."]],
        ["Credit Card Image (mobile, tablet, desktop)", ["Width: 32px, Height: 20px"]],
        ["Credit Card Image Type", ["No limitation to file format recommendation."]],
        ["Title", ["A <h2> consists of string text"]],
        ["Copy Heading", ["A <p> consists of string text"]],
        ["Image Type", ["No limitation to file format recommendation."]],
        ["Question", ["A <span> consists of string text"]],
        ["Copy", ["A <p> consists of string text"]],
      ],
      cis: {
        cmsDoc:
          "https://kingstontechnology.sharepoint.com/:w:/s/creative_fv/EY9eExcb5pZIob4yq2PTZ20Bo2ReQwuybrgZOkHPCZiANg?e=1RVJ2f",
        comments: null,
      },
    };

    setAppState(ckVars);
    setContentOptions({
      savings: { label: "Savings", checkbox: true, field: null },
      currentLang: "Eng",
    });
    setDimensions({
      ...dimensions,
      viewHeight: "1323",
      hardcodeHeight: false,
      width: window.innerWidth - 24,
    });
  }, []);

  if (!contentOptions) return "...Loading Checkout: Emea";
  return (
    <section className={"s-checkout s-checkout--thankyou t-white"}>
      <div className="c-heading">
        <div className="c-heading__block">
          <div className="c-heading__block__header">
            <h1>Thank You!</h1>
            <p>Order #900000100</p>
          </div>
        </div>
      </div>
      <div className="l-inner">
        <p>
          Your order is confirmed and is being processed. A confirmation email has been sent. Follow
          the instructions in the email to get status updates for your order.{" "}
        </p>
        <p>
          If you don't yet have a Kingston account, you can{" "}
          <a className="u-txt-bold" href="/unitedkingdom/en/shop/account/signup?c=True">
            create one now
          </a>
          , or follow the instructions in your email to get status updates for your order.
        </p>

        <div className="forms-create_account">
          <div className="footer">
            <a href="/unitedkingdom/en/shop/account/signup?c=True" className="e-btn process">
              Create Account
            </a>
          </div>
        </div>

        <a id="show-modal" data-modal="alertOverlay" className="hidden"></a>

        <div className="l-row">
          <div className="l-row__col l-1/2@md">
            <div
              id="gMap"
              data-key="AIzaSyC94UzFSRhuGY27WBhlqpusPJUcHO4QFck"
              data-location="555 East Orange Show Road,San Bernardino,CA 92408,"
            >
              <iframe
                className="google-map"
                src="//www.google.com/maps/embed/v1/place?q=555%20East%20Orange%20Show%20Road%2CSan%20Bernardino%2CCA%2092408%2C&amp;zoom=15&amp;key=AIzaSyC94UzFSRhuGY27WBhlqpusPJUcHO4QFck"
              ></iframe>
            </div>
          </div>
          <div className="l-row__col l-1/2@md">
            <div className="c-accordion c-accordion--iconAfter">
              <span className="c-accordion__tab c-accordion__tab--active" id="accTab-l5uokg">
                <button aria-controls="accPanel-zhzu41" aria-expanded="true">
                  Order Summary
                  <svg
                    className="c-accordion__tab__icon"
                    aria-hidden="true"
                    width="14"
                    height="8"
                    viewBox="0 0 14 8"
                  >
                    <path d="M13.02.02a.062.062 0 0 0-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 0 0-.09 0L.02.977a.062.062 0 0 0 0 .09l6.937 6.915a.066.066 0 0 0 .09 0l.962-.96 5.973-5.954a.065.065 0 0 0 0-.09L13.02.02z"></path>
                  </svg>
                  <span className="u-txt-bold">$100.00</span>
                </button>
              </span>
              <div
                className="c-accordion__panel"
                id="accPanel-zhzu41"
                aria-labelledby="accTab-l5uokg"
                //--delete this before committing. demo only
                style={{ maxHeight: "500px" }}
              >
                <div className={`c-accordion__panel__content c-cartCheckout`}>
                  <ul className="u-list-unstyled c-cartCheckout__listing u-m0">
                    <li>
                      <table className="c-cartCheckout__listing__item">
                        <tbody>
                          <tr>
                            <td>
                              <img
                                src="https://media.kingston.com/kingston/product/ktc-product-usb-dt70-64gb-1-tn.png"
                                alt="product usb dt70 64gb"
                              />
                            </td>
                            <td>
                              Product 1
                              <span className="c-cartCheckout__listing__item__pricePrimary u-txt-bold">
                                1 x $5.23
                              </span>
                            </td>
                          </tr>
                          <tr></tr>
                        </tbody>
                      </table>
                    </li>

                    <li>
                      <table className="c-cartCheckout__listing__item">
                        <tbody>
                          <tr>
                            <td>
                              <img
                                src="https://media.kingston.com/kingston/product/ktc-product-usb-dt70-64gb-1-tn.png"
                                alt="product usb dt70 64gb"
                              />
                            </td>
                            <td>
                              Product 2
                              <span className="c-cartCheckout__listing__item__pricePrimary u-txt-bold">
                                1 x $5.23
                              </span>
                            </td>
                          </tr>
                          <tr></tr>
                        </tbody>
                      </table>
                    </li>
                  </ul>
                  <div className="c-cartCheckout__summary">
                    <table className="c-cartCheckout__summary__details">
                      <tbody>
                        <tr>
                          <td className="l-summary__itemCount">1 Item</td>
                          <td className="l-summary__totalTitle">Total</td>
                          <td className="l-summary__total">$2.50</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td className="l-summary__exVatTotal">Total (VAT excluded)</td>
                          <td className="l-summary__exVatTotal">$0.10</td>
                        </tr>
                        {contentOptions.savings && contentOptions.savings.checkbox == true ? (
                          <tr>
                            <td></td>
                            <td className="l-summary__saveTitle">Savings</td>
                            <td className="l-summary__savings">$0.10</td>
                          </tr>
                        ) : (
                          ""
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved.
export default {
  path: "checkout-thankyou",
  component: S_CheckoutThankYou,
  navtxt: "Checkout: Thank You",
  htmlName: "CheckoutThankYou",
  categoryType: ["internal"],
};
