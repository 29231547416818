export default {
  heading: {
    label: "Heading",
    checkbox: null,
    field: {
      Eng: "DataTraveler Max USB 3.2 Gen 2 Series Flash Drive",
      Span: "Función Clave",
      ChS: "关键特点",
    },
    charLimit: 70,
  },
  desc: {
    label: "Subheading",
    checkbox: true,
    field: {
      Eng: "Etiam sit amet nisl purus in mollis nunc sed id semper.",
      Span: "Aquí hay un texto que es una descripción de la función.",
      ChS: "这里有一些文字是特征描述。",
    },
    charLimit: 200,
  },
  logo: {
    label: "Brand logo",
    checkbox: null,
    field: ["kingston", "ironkey", "fury"],
    selected: "kingston",
  },

  currentLang: "Eng",
};
