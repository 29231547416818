/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-interactive-element-to-noninteractive-role */
/* eslint-disable jsx-a11y/role-has-required-aria-props */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";
import DropZone from "../../../0_hooks/dropzone";

let SmallImg = "/images/fpo/general/100x100.png";
let MedImg = "/images/fpo/general/500x500.png";
let QRImg = "/images/fpo/general/500x500QR.png";

const S_Modals = () => {
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);

  const [featureImage1, setFeatureImage1] = useState(SmallImg);
  const [featureImage2, setFeatureImage2] = useState(QRImg);
  const [featureImage3, setFeatureImage3] = useState(MedImg);
  const [toolTipLeft] = useState("0");
  const [toolTipTop] = useState("150");

  const tip1 = useRef();
  const tip1Parent = useRef();
  const tip2 = useRef();
  const tip2Parent = useRef();

  const [currModal, setCurrModal] = useState(0);

  useEffect(() => {
    let Vars = {
      ...appState,
      currentPath: "/ui/content",
      outputName: "Modals",
      headerName: "Modals",
      tags: null,
      description: "The Modals Section displays the modals and dialog boxes used on the site.",
      related: null,
      htmlSaved: true,
      currentTheme: "t-stone",
      scripts: [
        "../../js/rexusManager.component.js",
        "../../js/cuid.component.js",
        "../../js/formsLegacy.component.js",
        "../../js/tooltip.component.js",
        "../../js/modal.component.js",
        "../../js/dialog.component.js",
        "../../js/socials.component.js",
      ],
      specs: [["Modals", ["TBD"]]],
      cis: null,
    };
    setAppState(Vars);

    setContentOptions({
      currentLang: "Eng",
      ddm1: {
        label: "Modal Default Dialog",
        checkbox: true,
        field: "This is a default dialog inside a modal",
      },
      m2: {
        label: "Modal Dialog Text",
        checkbox: true,
        field:
          "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptatem fugit id repellat atque porro architecto rem eius illo. Modi id exercitationem nostrum nobis ad esse dicta perspiciatis. Quae sequi cum porro unde dolor est beatae reiciendis facere!",
      },
      ctalink1: {
        label: "CTA Link in Modal Dialog",
        checkbox: true,
        field: "CTA Link",
      },
      btn1: {
        label: "Modal Dialog Btn 1",
        checkbox: true,
        field: "CTA Button 1",
      },
      btn2: {
        label: "Modal Dialog Btn 1",
        checkbox: true,
        field: "CTA Button 1",
      },
    });
    setDimensions({
      ...dimensions,
      viewHeight: "650",
      hardcodeHeight: true,
      width: window.innerWidth - 24,
    });
  }, []);

  // useEffect(() => {
  //ToDo:calc tooltip default position here
  //add scroll and window resize to dep array
  // }, []);

  if (!contentOptions) return "...Loading Content";
  return (
    <section className={`s-content l-1/1 ` + appState.currentTheme}>
      <div className="l-inner">
        <div className="l-row u-mb">
          <div style={{ height: "400px" }}>
            <h1>Example content here</h1>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt sed, ea
              consequuntur. Laudantium sint veritatis soluta consectetur fugit saepe numquam. Enim
              maxime veritatis ea quia eos quam et sunt amet voluptatem aperiam, necessitatibus,
              est. Delectus repellat, quibusdam quidem, pariatur veritatis aspernatur nobis sed a
              minus autem ut, totam corrupti debitis. Lorem ipsum dolor sit amet, consectetur
              adipisicing elit. Deserunt sed, ea consequuntur. Laudantium sint veritatis soluta
              consectetur fugit saepe numquam. Enim maxime veritatis ea quia eos quam et sunt amet
              voluptatem aperiam, necessitatibus, est. Delectus repellat, quibusdam quidem, pariatur
              veritatis aspernatur nobis sed a minus autem ut, totam corrupti debitis. Lorem ipsum
              dolor sit amet, consectetur adipisicing elit. Deserunt sed, ea consequuntur.
              Laudantium sint veritatis soluta consectetur fugit saepe numquam. Enim maxime
              veritatis ea quia eos quam et sunt amet voluptatem aperiam, necessitatibus, est.
              Delectus repellat, quibusdam quidem, pariatur veritatis aspernatur nobis sed a minus
              autem ut, totam corrupti debitis.
            </p>
          </div>
        </div>
        <div className="l-row">
          <div className="l-row__col l-1/1">
            <h3>Modals and Dialogs</h3>
            {/* Begin pop up modal code */}

            <button className="e-btn" data-modal="image" onClick={() => setCurrModal(2)}>
              Modal Button w/ Image
            </button>
            <div
              className={`c-modal ${currModal === 2 && "c-modal--open"}`}
              role="dialog"
              id="image"
              aria-modal="true"
              aria-hidden={currModal === 2 ? "false" : "true"}
            >
              <div className="c-modal__overlay"></div>
              <button
                className="c-modal__close"
                aria-label="close"
                data-close="true"
                onClick={() => setCurrModal(0)}
              >
                <svg viewBox="0 0 14 14">
                  <path d="M.46 12.023L11.772.709l1.768 1.768L2.227 13.791z"></path>
                  <path d="M2.227.71l11.314 11.313-1.768 1.768L.459 2.477z"></path>
                </svg>
              </button>
              <div className="c-modal__content">
                <DropZone setImage={setFeatureImage1}>
                  <img src={featureImage1} alt="Alternate Text" />
                </DropZone>
              </div>
            </div>
            <button className="e-btn" data-modal="dialog" onClick={() => setCurrModal(3)}>
              Default Dialog within Modal
            </button>
            <div
              className={`c-modal ${currModal === 3 && "c-modal--open"}`}
              role="dialog"
              id="dialog"
              aria-modal="true"
              aria-hidden={currModal === 3 ? "false" : "true"}
            >
              <div className="c-modal__overlay"></div>
              <div className="c-modal__content">
                <div className="c-dialog u-autoMargin" tabIndex="0" aria-labelledby="dialogHeader0">
                  <header className="c-dialog__header" role="heading" id="dialogHeader0">
                    <button
                      className="c-dialog__header__close"
                      aria-label="close"
                      data-close="true"
                      onClick={() => setCurrModal(0)}
                    >
                      <svg viewBox="0 0 14 14">
                        <path d="M.46 12.023L11.772.709l1.768 1.768L2.227 13.791z"></path>
                        <path d="M2.227.71l11.314 11.313-1.768 1.768L.459 2.477z"></path>
                      </svg>
                    </button>
                    <p>{contentOptions.ddm1 && contentOptions.ddm1.field}</p>
                  </header>
                  <div className="c-dialog__content">
                    <p>{contentOptions.m2 && contentOptions.m2.field}</p>
                  </div>
                  <div className="c-dialog__cta">
                    <div className="c-dialog__cta__links">
                      <a className="u-txt-small" href="#dialogLink">
                        {contentOptions.ctalink1 && contentOptions.ctalink1.field}
                      </a>
                    </div>
                    <div className="c-dialog__cta__btns">
                      <button className="e-btn" data-close="true" onClick={() => setCurrModal(0)}>
                        {contentOptions.btn1 && contentOptions.btn1.field}
                      </button>
                      <button
                        className="e-btn e-btn--alt1"
                        data-close="true"
                        onClick={() => setCurrModal(0)}
                      >
                        {contentOptions.btn2 && contentOptions.btn2.field}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button className="e-btn" data-modal="email" onClick={() => setCurrModal(4)}>
              Email Sign Up
            </button>
            <div
              className={`c-modal ${
                currModal === 4 ? "c-modal--open" : currModal === 4.5 ? "c-modal--open" : ""
              }`}
              role="dialog"
              id="email"
              aria-modal="true"
              aria-hidden={currModal === 4 ? "false" : currModal === 4.5 ? "false" : "true"}
            >
              <div className="c-modal__overlay"></div>
              <div className="c-modal__content">
                <div
                  className="c-dialog u-autoMargin c-dialog--form"
                  tabIndex="0"
                  aria-labelledby="dialogHeader1"
                >
                  <header className="c-dialog__header" role="heading" id="dialogHeader1">
                    <button
                      className="c-dialog__header__close"
                      aria-label="close"
                      data-close="true"
                      onClick={() => setCurrModal(0)}
                    >
                      <svg viewBox="0 0 14 14">
                        <path d="M.46 12.023L11.772.709l1.768 1.768L2.227 13.791z"></path>
                        <path d="M2.227.71l11.314 11.313-1.768 1.768L.459 2.477z"></path>
                      </svg>
                    </button>
                    <h3>By leaving your email address, we may contact you about this topic</h3>
                  </header>
                  <div className="c-dialog__content">
                    <p>
                      Please sign me up for emails from
                      <a
                        href="#"
                        className="c-tooltip c-tooltip--top"
                        data-toolid="kingston"
                        role="tooltip"
                        aria-describedby="kingston"
                        onMouseEnter={() => setCurrModal(4.5)}
                        onMouseLeave={() => setCurrModal(4)}
                        ref={tip1Parent}
                      >
                        {" "}
                        Kingston{" "}
                      </a>
                      about its products, services and news.
                    </p>
                    <div className="c-dialog__content__radio">
                      <div className="f-input">
                        <div className="f-input__checkbox">
                          <input
                            type="checkbox"
                            name="checkboxName"
                            id="checkboxName0"
                            value="Personal"
                          />
                          <label htmlFor="checkboxName0" aria-checked="false" tabIndex="0">
                            Personal
                          </label>
                        </div>
                        <div className="f-input__checkbox">
                          <input
                            type="checkbox"
                            name="checkboxName"
                            id="checkboxName1"
                            value="Gaming"
                          />
                          <label htmlFor="checkboxName1" aria-checked="false" tabIndex="0">
                            Gaming
                          </label>
                        </div>
                        <div className="f-input__checkbox">
                          <input
                            type="checkbox"
                            name="checkboxName"
                            id="checkboxName2"
                            value="Commercial"
                          />
                          <label htmlFor="checkboxName2" aria-checked="false" tabIndex="0">
                            Commercial
                          </label>
                        </div>
                      </div>
                    </div>
                    <form id="js-collapseExample">
                      <div className="f-input">
                        <div className="f-input__string">
                          <input type="text" name="inputName" id="inputName" />
                          <label htmlFor="inputName">Email Address</label>
                        </div>
                      </div>
                      <button
                        className="e-btn"
                        title="Button Title"
                        id="submitBtnId"
                        data-collapse="js-collapseExample"
                        onClick={() => setCurrModal(0)}
                      >
                        <span>Sign Up Now</span>
                      </button>
                    </form>
                    <p
                      id="demoMsg"
                      style={{ display: "none", opacity: "0", transition: "opacity .5s" }}
                    >
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </p>
                    <p className="reCAPTCHA">
                      This site is protected by reCAPTCHA and the
                      <a href="#" className="u-txt-bold">
                        Google Privacy Policy
                      </a>
                      and
                      <a href="#" className="u-txt-bold">
                        Terms of Service
                      </a>
                      apply.
                    </p>
                    <p>
                      We’ll handle your information in line with our
                      <a href="#">privacy policy</a>. To ‘unsubscribe’ click the link at the bottom
                      of our emails.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`c-tooltip__message ${currModal === 4.5 ? "c-tooltip--active" : ""}`}
              id="kingston"
              ref={tip1}
              style={{ top: toolTipTop + "px", left: toolTipLeft + "px", position: "relative" }}
            >
              <p>The tooltip&apos;s message will appear above the SPAN or ANCHOR tag.</p>
            </div>
            <button className="e-btn" data-modal="emailDark" onClick={() => setCurrModal(11)}>
              Email Sign Up w/ Dark Mode
            </button>
            <div
              className={`c-modal c-modal--darkmode ${currModal === 11 && "c-modal--open"}`}
              role="dialog"
              id="emailDark"
              aria-modal="true"
              aria-hidden={currModal === 11 ? "false" : "true"}
            >
              <div className="c-modal__overlay"></div>
              <div className="c-modal__content">
                <div
                  className="c-dialog u-autoMargin c-dialog--form"
                  tabIndex="0"
                  aria-labelledby="dialogHeader2"
                >
                  <div className="c-dialog__header" id="dialogHeader2">
                    <button
                      className="c-dialog__header__close"
                      aria-label="close"
                      data-close="true"
                      onClick={() => setCurrModal(0)}
                    >
                      <svg viewBox="0 0 14 14">
                        <path d="M.46 12.023L11.772.709l1.768 1.768L2.227 13.791z"></path>
                        <path d="M2.227.71l11.314 11.313-1.768 1.768L.459 2.477z"></path>
                      </svg>
                    </button>
                    <h3>By leaving your email address, we may contact you about this topic</h3>
                  </div>
                  <div className="c-dialog__content">
                    <form id="js-collapseExample2">
                      <div className="f-input" data-id="fi-0qkzfz">
                        <div className="f-input__string">
                          <input
                            type="text"
                            name="businessEmail"
                            id="businessEmail"
                            data-root="fi-0qkzfz"
                          />
                          <label htmlFor="businessEmail">Business Email</label>
                        </div>
                      </div>
                      <div className="l-row">
                        <div className="l-row__col l-1/1 l-1/2@md">
                          <div className="f-input" data-id="fi-jew8pi">
                            <div className="f-input__string">
                              <input
                                type="text"
                                name="firstname"
                                id="firstname"
                                data-root="fi-jew8pi"
                              />
                              <label htmlFor="firstname">First Name</label>
                            </div>
                          </div>
                        </div>
                        <div className="l-row__col l-1/1 l-1/2@md">
                          <div className="f-input" data-id="fi-ck991l">
                            <div className="f-input__string">
                              <input
                                type="text"
                                name="lastname"
                                id="lastname"
                                data-root="fi-ck991l"
                              />
                              <label htmlFor="lastname">Last Name</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="f-input" data-id="fi-mlrqlo">
                        <div className="f-input__string">
                          <input type="text" name="company" id="company" data-root="fi-mlrqlo" />
                          <label htmlFor="company">Company</label>
                        </div>
                      </div>
                      <div
                        className="f-input"
                        data-id="fi-tkc8ly"
                        style={{ flexWrap: "wrap", borderStyle: "none" }}
                      >
                        <div className="f-input__checkbox">
                          <input
                            type="checkbox"
                            name="filterBtnsID1"
                            id="filterBtnsID1"
                            value="filterBtnsID1"
                            data-root="fi-tkc8ly"
                          />
                          <label htmlFor="filterBtnsID1">
                            Please sign me up for emails from Kingston about its products, services
                            and news.
                          </label>
                        </div>
                      </div>
                      <button
                        className="e-btn"
                        title="Button Title"
                        id="submitBtnId2"
                        data-collapse="js-collapseExample2"
                      >
                        <span>Sign Up Now</span>
                      </button>
                    </form>
                    <p
                      id="demoMsg"
                      style={{ display: "none", opacity: "0", transition: "opacity .5s" }}
                    >
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </p>
                    <p className="reCAPTCHA">
                      This site is protected by reCAPTCHA and the{" "}
                      <a href="#link" className="u-txt-bold">
                        Google Privacy Policy
                      </a>{" "}
                      and{" "}
                      <a href="#link;" className="u-txt-bold">
                        Terms of Service
                      </a>{" "}
                      apply.
                    </p>
                    <p>We use legitimate interest to advise you about our products.</p>
                    <p>
                      We’ll handle your information in line with our{" "}
                      <a href="#link">Privacy Policy</a>. To ‘unsubscribe’ click the link at the
                      bottom of our emails.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <button className="e-btn" data-modal="qr" onClick={() => setCurrModal(5)}>
              Dialog - QR
            </button>
            <div
              className={`c-modal ${currModal === 5 && "c-modal--open"}`}
              role="dialog"
              id="qr"
              aria-modal="true"
              aria-hidden={currModal === 5 ? "false" : "true"}
            >
              <div className="c-modal__overlay"></div>
              <div className="c-modal__content">
                <div className="c-dialog u-autoMargin" tabIndex="0" aria-labelledby="dialogHeader2">
                  <header className="c-dialog__header" role="heading" id="dialogHeader2">
                    <button
                      className="c-dialog__header__close"
                      aria-label="close"
                      data-close="true"
                      onClick={() => setCurrModal(0)}
                    >
                      <svg viewBox="0 0 14 14">
                        <path d="M.46 12.023L11.772.709l1.768 1.768L2.227 13.791z"></path>
                        <path d="M2.227.71l11.314 11.313-1.768 1.768L.459 2.477z"></path>
                      </svg>
                    </button>
                    <p>QR Code</p>
                  </header>
                  <div className="c-dialog__content">
                    <DropZone setImage={setFeatureImage2}>
                      <img src={featureImage2} alt="Alternate Text" />
                    </DropZone>
                  </div>
                  <div className="c-dialog__cta">
                    <div className="c-dialog__cta__btns">
                      <button className="e-btn" data-close="true" onClick={() => setCurrModal(0)}>
                        Continue to Social Media
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button className="e-btn" data-modal="longerDialog" onClick={() => setCurrModal(6)}>
              Dialog - Long Content
            </button>
            <div
              className={`c-modal ${currModal === 6 && "c-modal--open"}`}
              role="dialog"
              id="longerDialog"
              aria-modal="true"
              aria-hidden={currModal === 6 ? "false" : "true"}
            >
              <div className="c-modal__overlay"></div>
              <div className="c-modal__content">
                <div className="c-dialog u-autoMargin" tabIndex="0" aria-labelledby="dialogHeader3">
                  <header className="c-dialog__header" role="heading" id="dialogHeader3">
                    <button
                      className="c-dialog__header__close"
                      aria-label="close"
                      data-close="true"
                      onClick={() => setCurrModal(0)}
                    >
                      <svg viewBox="0 0 14 14">
                        <path d="M.46 12.023L11.772.709l1.768 1.768L2.227 13.791z"></path>
                        <path d="M2.227.71l11.314 11.313-1.768 1.768L.459 2.477z"></path>
                      </svg>
                    </button>
                    <p>
                      Modals are built with Accessibility in mind. The dialog becomes scrollable
                      when its content is longer than usual
                    </p>
                  </header>
                  <div className="c-dialog__content">
                    <p>
                      External tabbed location is preserved and can only tab trhough modal elements
                      while modal is open. Dialogs also reach a certain height limit before becoming
                      scrollable.
                    </p>
                    <DropZone setImage={setFeatureImage3}>
                      <img src={featureImage3} alt="Alternate Text" />
                    </DropZone>
                    <p>
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptatem fugit id
                      repellat atque porro architecto rem eius illo. Modi id exercitationem nostrum
                      nobis ad esse dicta perspiciatis. Quae sequi cum porro unde dolor est beatae
                      reiciendis facere!
                    </p>
                  </div>
                  <div className="c-dialog__cta">
                    <div className="c-dialog__cta__btns">
                      <button className="e-btn" data-close="true">
                        Longer CTA Button
                      </button>
                      <button className="e-btn e-btn--alt1" data-close="true">
                        Short CTA
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button className="e-btn" data-modal="tooltip" onClick={() => setCurrModal(7)}>
              Dialog - Tooltip
            </button>
            <div
              className={`c-modal ${
                currModal === 7 ? "c-modal--open" : currModal === 7.5 ? "c-modal--open" : ""
              }`}
              role="dialog"
              id="tooltip"
              aria-modal="true"
              aria-hidden={currModal === 7 ? "false" : currModal === 7.5 ? "false" : "true"}
            >
              <div className="c-modal__overlay"></div>
              <div className="c-modal__content">
                <div className="c-dialog u-autoMargin" tabIndex="0" aria-labelledby="dialogHeader4">
                  <header className="c-dialog__header" role="heading" id="dialogHeader4">
                    <button
                      className="c-dialog__header__close"
                      aria-label="close"
                      data-close="true"
                      onClick={() => setCurrModal(0)}
                    >
                      <svg viewBox="0 0 14 14">
                        <path d="M.46 12.023L11.772.709l1.768 1.768L2.227 13.791z"></path>
                        <path d="M2.227.71l11.314 11.313-1.768 1.768L.459 2.477z"></path>
                      </svg>
                    </button>
                    <p>Dialog with a Tooltip</p>
                  </header>
                  <div className="c-dialog__content">
                    <p>
                      Please sign up with
                      <span
                        className="c-tooltip c-tooltip--top"
                        data-toolid="privacy-policy"
                        role="tooltip"
                        tabIndex="0"
                        aria-describedby="privacy-policy"
                        ref={tip2Parent}
                        onMouseEnter={() => setCurrModal(7.5)}
                        onMouseLeave={() => setCurrModal(7)}
                      >
                        {" "}
                        Kingston{" "}
                      </span>
                      about its products and services.
                    </p>
                  </div>
                  <div className="c-dialog__cta">
                    <div className="c-dialog__cta__btns">
                      <button className="e-btn" data-close="true" onClick={() => setCurrModal(0)}>
                        Longer CTA Button
                      </button>
                      <button
                        className="e-btn e-btn--alt1"
                        data-close="true"
                        onClick={() => setCurrModal(0)}
                      >
                        Short CTA
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`c-tooltip__message ${currModal === 7.5 ? "c-tooltip--active" : ""}`}
              id="privacy-policy"
              ref={tip2}
              style={{ top: "32%", left: "35%" }}
            >
              <p>The tooltip&apos;s message will appear above the SPAN or ANCHOR tag.</p>
            </div>
            <button className="e-btn" data-modal="ytVideo" onClick={() => setCurrModal(8)}>
              Youtube within Modal
            </button>
            <div
              className={`c-modal c-modal--darkmode ${currModal === 8 && "c-modal--open"}`}
              role="dialog"
              id="ytVideo"
              aria-modal="true"
              aria-hidden={currModal === 8 ? "false" : "true"}
            >
              <div className="c-modal__overlay"></div>
              <button
                className="c-modal__close"
                aria-label="close"
                data-close="true"
                onClick={() => setCurrModal(0)}
              >
                <svg viewBox="0 0 14 14">
                  <path d="M.46 12.023L11.772.709l1.768 1.768L2.227 13.791z"></path>
                  <path d="M2.227.71l11.314 11.313-1.768 1.768L.459 2.477z"></path>
                </svg>
              </button>
              <div className="c-modal__content">
                <div
                  className="c-externalVideo"
                  data-type="Youtube"
                  data-id="1MieluM0c6c"
                  data-starttime="1"
                  data-modal-link="ytVideo"
                >
                  <iframe
                    src="https://www.youtube.com/embed/1MieluM0c6c?start=1&amp;enablejsapi=1"
                    id="lnk-b6ozmb"
                    data-modal-link="ytVideo"
                  ></iframe>
                </div>
              </div>
            </div>
            <button className="e-btn" data-modal="vVideo" onClick={() => setCurrModal(9)}>
              Vimeo within Modal
            </button>
            <div
              className={`c-modal c-modal--darkmode ${currModal === 9 && "c-modal--open"}`}
              role="dialog"
              id="vVideo"
              aria-modal="true"
              aria-hidden={currModal === 9 ? "false" : "true"}
            >
              <div className="c-modal__overlay"></div>
              <button
                className="c-modal__close"
                aria-label="close"
                data-close="true"
                onClick={() => setCurrModal(0)}
              >
                <svg viewBox="0 0 14 14">
                  <path d="M.46 12.023L11.772.709l1.768 1.768L2.227 13.791z"></path>
                  <path d="M2.227.71l11.314 11.313-1.768 1.768L.459 2.477z"></path>
                </svg>
              </button>
              <div className="c-modal__content">
                <div
                  className="c-externalVideo"
                  data-type="Vimeo"
                  data-id="339160819"
                  data-starttime="1"
                  data-modal-link="vVideo"
                >
                  <iframe
                    src="https://player.vimeo.com/video/339160819?api=1#t=1s"
                    allow="autoplay"
                    webkitallowfullscreen=""
                    mozallowfullscreen=""
                    allowFullScreen=""
                    id="lnk-laao44"
                    data-modal-link="vVideo"
                  ></iframe>
                </div>
              </div>
            </div>
            <button className="e-btn" data-modal="deactivate" onClick={() => setCurrModal(10)}>
              Deactivate Close
            </button>
            <div
              className={`c-modal ${currModal === 10 && "c-modal--open"}`}
              role="dialog"
              id="deactivate"
              aria-modal="true"
              aria-hidden={currModal === 10 ? "false" : "true"}
              data-deactivateclose="data-deactivateClose"
            >
              <div className="c-modal__overlay"></div>
              <div className="c-modal__content">
                <div className="c-dialog u-autoMargin" tabIndex="0" aria-labelledby="dialogHeader5">
                  <header className="c-dialog__header" role="heading" id="dialogHeader5">
                    <p>This is a default dialog inside a modal</p>
                  </header>
                  <div className="c-dialog__content">
                    <p>
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptatem fugit id
                      repellat atque porro architecto rem eius illo. Modi id exercitationem nostrum
                      nobis ad esse dicta perspiciatis. Quae sequi cum porro unde dolor est beatae
                      reiciendis facere!
                    </p>
                  </div>
                  <div className="c-dialog__cta">
                    <div className="c-dialog__cta__links">
                      <a className="u-txt-small" href="#dialogLink" onClick={() => setCurrModal(0)}>
                        CTA Link
                      </a>
                    </div>
                    <div className="c-dialog__cta__btns">
                      <button className="e-btn" data-close="true">
                        CTA Button 1
                      </button>
                      <button
                        className="e-btn e-btn--alt1"
                        data-close="true"
                        onClick={() => setCurrModal(0)}
                      >
                        CTA Button 2
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button className="e-btn" data-modal="pretty" onClick={() => setCurrModal(12)}>
              Pretty Modal
            </button>
            <div
              className={`c-modal c-modal--darkmode ${currModal === 12 && "c-modal--open"}`}
              role="dialog"
              id="pretty"
              aria-modal="true"
              aria-hidden={currModal === 12 ? "false" : "true"}
              aria-labelledby="dialogStyle2Header"
              data-deactivateclose="data-deactivateClose"
            >
              <div className="c-modal__overlay"></div>
              <div className="c-modal__content">
                <div className="c-dialog c-dialog--style2 u-autoMargin">
                  <button className="c-dialog__close" aria-label="Close modal" data-close="true">
                    <svg viewBox="0 0 14 14">
                      <path d="M.46 12.023L11.772.709l1.768 1.768L2.227 13.791z"></path>
                      <path d="M2.227.71l11.314 11.313-1.768 1.768L.459 2.477z"></path>
                    </svg>
                  </button>
                  <h2 className="c-dialog__heading" id="dialogStyle2Header">
                    Replacement list
                  </h2>
                  <div className="c-dialog__container">
                    <div className="c-dialog__container__content">
                      <ul className="u-list-unstyled">
                        <li>
                          <a href="#">Link 1</a>
                        </li>
                        <li>
                          <a href="#">Link 1</a>
                        </li>
                        <li>
                          <a href="#">Link 1</a>
                        </li>
                        <li>
                          <a href="#">Link 1</a>
                        </li>
                        <li>
                          <a href="#">Link 1</a>
                        </li>
                        <li>
                          <a href="#">Link 1</a>
                        </li>
                        <li>
                          <a href="#">Link 1</a>
                        </li>
                        <li>
                          <a href="#">Link 1</a>
                        </li>
                        <li>
                          <a href="#">Link 1</a>
                        </li>
                        <li>
                          <a href="#">Link 1</a>
                        </li>
                        <li>
                          <a href="#">Link 1</a>
                        </li>
                        <li>
                          <a href="#">Link 1</a>
                        </li>
                        <li>
                          <a href="#">Link 1</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ or C_. ex: htmlName: "KeyFeature",
export default {
  path: "modals",
  component: S_Modals,
  navtxt: "Modals",
  htmlName: "Modals",
  categoryType: ["web"],
};
